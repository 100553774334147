import styled from 'styled-components';
import { responsive } from 'styled-minimal';

import { spacer } from 'modules/theme';

export const WelcomeContainer = styled.div`
  margin: 100px 140px 20px 100px;
  p {
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.22580644488334656px;
    text-align: center;
    color: #4d5157;
  }
  .welcomePbl {
    max-width: 280px;
    margin-right: 50px;
    p {
      text-align: left;
    }

    h1 {
      font-family: DM Sans;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 41px;
      letter-spacing: -0.5806451439857483px;
      text-align: left;
      color: #1c1939;
    }
  }
  .welcomeUpload {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 380px;
    padding: 50px 60px;
    margin-right: 35px;
    height: 380px;
    background: #f5f5f9;
    img {
      width: 100px;
      height: 100px;
    }
    .uploadContainer {
      .fileUploadStyle {
        cursor: auto;
        margin-right: 0;
      }
      .text-danger {
        color: red;
        .invalid-feedback {
          color: red;
        }
      }
    }
    h1 {
      margin: 0;
      font-family: DM Sans;
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: -0.3709677457809448px;
      text-align: center;
      color: #6a52b0;
    }
  }
  .beUnlimited {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 40px;
    }
    .getUnlimited {
      .getUnlimitedMore {
        margin-right: 10px;
        font-family: DM Sans;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: -0.3709677457809448px;
        text-align: center;
      }
      .getUnlimitedGet {
        font-family: DM Sans;
        font-size: 23px;
        font-style: normal;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: -0.3709677457809448px;
        text-align: center;
      }
    }
    text-align: center;
    max-width: 350px;
    h1 {
      font-family: DM Sans;
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: -0.3709677457809448px;
      text-align: center;
    }
  }
  display: flex;
  justify-content: space-between;
  .becomePro {
    width: 150px;
  }
`;
export const MyAppsContainerList = styled.div`
  display: flex;
  margin: 10px 35px 40px 35px;

  .myAppsListContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .campaign-wrap {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .campaign-top {
      width: 100%;
    }
    .campaignTopBlock {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 23px;
      button {
        width: 125px;
        margin: 0 0 0 20px;
      }
    }
    .myAppsTitleContainer {
      width: 100%;
      display: flex;
      margin-top: 17px;
      margin-bottom: 27px;
      align-items: center;
      justify-content: space-between;
      .userAppsInfo {
        display: flex;
        .container {
          &:first-child {
            margin-left: 0;
          }
          border-radius: 12px;
          margin-left: 24px;
          padding: 21px 24px;
          display: flex;
          justify-content: space-between;
          width: 192px;
          height: 78px;
          align-items: flex-end;
          background: #ffffff;
          box-shadow: 10px 21px 34px rgba(106, 82, 176, 0.09);
          .icon {
            width: 17px;
            height: 17px;
          }
          .info {
            .count {
              font-family: DM Sans;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 26px;
              letter-spacing: 0px;
              text-align: left;
              color: #8769e1;
            }
            .title {
              font-family: DM Sans;
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
              letter-spacing: 0px;
              text-align: left;
              color: #1c1939;
            }
          }
        }
      }
      .myAppsTitle {
        font-family: DM Sans;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: -0.3709677457809448px;
        text-align: left;
      }
    }
    .uploadNewContainer {
      cursor: pointer;
      width: 100%;
      background: rgba(106, 82, 176, 0.11);
      border: 1px dashed #6a52b0;
      border-radius: 12px;
      margin-bottom: 12px;
      .uploadNew {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: #6a52b0;
        height: 56px;
        svg {
          margin-right: 20px;
        }
      }
    }
    .appsList {
      .appContainer {
        display: flex;
        justify-content: space-between;
        border-radius: 12px;
        margin-bottom: 12px;
        padding: 20px 40px 15px 15px;
        background: #f5f5f9;
        .count {
          &.count-cents {
            .title {
              margin-left: 5px;
            }
          }
        }
        .campaign-buttons {
          svg {
            margin: 0 20px;
            cursor: pointer;
          }
        }
        .appButtonsContainer {
          .reactTooltip {
            margin-left: 30px;
          }
          .buttons {
            align-items: center;
            display: flex;
            button {
              cursor: pointer;
              &:disabled {
                cursor: not-allowed;
              }
            }
          }
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .myCampaignListContainer {
    display: flex;
    flex-direction: row;
  }
  .anyAppsContainer {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    justify-content: center;
    .anyAppsTitle {
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: -0.3709677457809448px;
      text-align: center;
      color: #6a52b0;
    }
    .anyAppsDescription {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.22580644488334656px;
      text-align: center;
      color: #4d5157;
      width: 260px;
      margin-top: 15px;
    }
    .anyAppsButton {
      width: 143px;
      margin-top: 32px;
    }
  }
  .campaign-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    .content {
      width: 100%;
      margin-bottom: 40px;
      .getUnlimitedContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 80px;
        .becomePro {
          width: 140px;
          margin-top: 20px;
        }
        .unlimitedBlock {
          margin-right: 44px;
          width: 404px;
          .unlimitedTitle {
            font-family: DM Sans;
            font-size: 23px;
            font-style: normal;
            font-weight: 400;
            line-height: 29px;
            span {
              font-weight: 700;
            }
          }
          .unlimitedDescription {
            margin-top: 10px;
            width: 300px;
            font-family: DM Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }
  .startCampaignWrapper {
    max-width: 302px;
    max-height: 428px;
    display: flex;
    flex-direction: column;
    margin-top: 121px;
    margin-right: 35px;
  }
  .startCampaign {
    width: 100%;
    border: 1px solid rgba(135, 105, 225, 0.3);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-top: -80px;
      left: 35px;
      width: 144px;
      height: 162px;
    }
    position: relative;
    padding: 25px;
    text-align: center;
    border-radius: 8px;
    margin-left: 20px;
    .startCampaignTitle {
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: -0.3709677457809448px;
      text-align: center;
      color: #1c1939;
      margin: 38px 0 25px 0;
    }
    .startCampaignDescription {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.22580644488334656px;
      text-align: center;
      color: #4d5157;
      margin-bottom: 25px;
    }
    button {
      margin: 0 0 60px 0;
      width: 170px;
      height: 37px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
