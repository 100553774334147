import { CategoryService } from 'services/CategoryService';
import { AppListService } from 'services/AppListService';

export type ApiServicesType = typeof ApiServices;
export type ApiServicesKeys = keyof ApiServicesType;

export const ApiServices = {
  category: new CategoryService(),
  appList: new AppListService(),
};
