import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { appColor } from 'modules/theme';

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    height: 100%;
  }

  body {
    font-family: 'DM Sans', sans-serif;
    font-size: 16px; /* stylelint-disable unit-disallowed-list */
    margin: 0;
    min-height: 100vh;
    padding: 0;
  }

  img {
  }

  a {
    color: ${appColor};
    text-decoration: none;

    &.disabled {
      pointer-events: none;
    }
  }

  button {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    line-height: 1;
    padding: 0;
  }
  .alert {
    background: white !important;
    box-shadow: 0 14px 34px rgba(0, 0, 0, 0.14);
    border: none !important;
    width: 293px;
    height: 60px;
    border-radius: 5px !important;
    color: #1c1939 !important;
  }
   .alert-message {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;
      margin-left: 17px;
   }
   .alert-title {
     font-weight: bold;
     &:first-letter {
      text-transform: uppercase;
     }
   }
   .alert-icon {
      background-color: #fff !important;
   }
`;

export default function GlobalStyles() {
  return <GlobalStyle />;
}
