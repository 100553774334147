import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { DialogContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { ButtonDefault, CreateAppPopUp } from 'styles';

export default function NewsLetterDialog(props) {
  const { open, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          position: 'fixed',
          height: '381px',
          margin: '0',
          width: '463px',
          padding: 0,
          background: '#F5F5F9',
        },
      }}
    >
      <DialogContent
        style={{
          padding: '32px 32px 0 60px',
        }}
      >
        <CreateAppPopUp className="newsletter">
          <div className="popUpTitle">
            <div>Newsletter</div>
          </div>
          <p className="popUpText">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus sed sem erat lorem mollis
            aliquet. Tincidunt et sapien blandit phasellus est nibh sed. Tincidunt urna massa sed
            est lacus. Fermentum diam tincidunt leo sodales sit massa, volutpat orci, faucibus.
            Fames enim nullam urna cras eu commodo non. Congue enim suspendisse volutpat sem id.
            Enim eu, cras purus a.
          </p>
          <div className="bottomButtons">
            <ButtonDefault onClick={onClose} color="default" type="button">
              <div className="buttonContainer">
                <div className="buttonName">Ok</div>
              </div>
            </ButtonDefault>
          </div>
          <div className="closePopUp" onClick={onClose}>
            <SVG src={`${process.env.PUBLIC_URL}/media/icons/cancel.svg`} />
          </div>
        </CreateAppPopUp>
      </DialogContent>
    </Dialog>
  );
}
