import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { getAppList, getCampaigns, showDialog } from 'redux/actions';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MyAppsContainerList } from 'pages/MyApps/Style';
import { ButtonDefault } from 'styles';

import { useShallowEqualSelector } from 'modules/hooks';

import { STATUS } from 'literals';

import Loader from 'components/Loader';

import { StoreState } from 'types';

export const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    width: '100%',
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#d0d0ec',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#6A52B0',
  },
}))(LinearProgress);

export const getOriginalIMG = img =>
  img ? img.variations.find(v => v.variation === 'original')!.url : '';

function CampaignList(campaigns) {
  if (!campaigns.length) return null;

  return campaigns.map((campaign, index) => {
    const newDate: any = new Date();
    const startDate: any = new Date(campaign.start_date);
    const endDate: any = new Date(campaign.finish_date);
    const diff = Math.max(0, endDate - newDate);
    const range = endDate - startDate;
    let progress = (100 * diff) / range;

    if (isNaN(progress)) {
      progress = 100;
    }

    if (progress <= 0) {
      progress = 100;
    }

    if (progress > 100) {
      progress = 0;
    }

    return (
      <React.Fragment key={index}>
        <div title={progress.toString()} className="appsList">
          <div className="appContainer">
            {campaign.app && (
              <div className="appInfo">
                <div className="appImage">
                  <img src={getOriginalIMG(campaign.app.icon)} alt="facebook" />
                </div>
                <div className="appTitleContainer">
                  <div className="appTitle">{campaign.app.name}</div>
                  {campaign.app.categories.length && (
                    <div className="appCategory">{campaign.app.categories[0].name}</div>
                  )}
                </div>
              </div>
            )}
            <div className="progress">
              <BorderLinearProgress
                className="progress-line"
                variant="determinate"
                value={progress || 0}
              />
              <div className="dates">
                <div>
                  Start
                  <span className="dates-start">
                    {campaign.start_date.toString().substr(0, 10).split('-').join('/')}
                  </span>
                </div>
                <div>
                  End
                  <span className="dates-end">
                    {campaign.finish_date.toString().substr(0, 10).split('-').join('/')}
                  </span>
                </div>
              </div>
            </div>
            <div className="appButtonsContainer">
              <div className="additionalInfo">
                <div className="count">{campaign.impressions}</div>
                <div className="title">Impressions</div>
              </div>
              <div className="additionalInfo">
                <div className="count">{campaign.app.installs.length}</div>
                <div className="title">Times added</div>
              </div>
              <div className="additionalInfo">
                <div className="count count-cents">
                  {campaign.impressions
                    ? (campaign.impressions / campaign.payment.amount).toFixed(2)
                    : campaign.impressions}
                  <div className="title">Cents</div>
                </div>
                <div className="title">Cost per impressions</div>
              </div>
              <div className="additionalInfo">
                <div className="count count-cents">
                  {campaign.app.installs.length
                    ? (campaign.app.installs.length / campaign.payment.amount).toFixed(2)
                    : campaign.app.installs.length}
                  <div className="title">Cents</div>
                </div>
                <div className="title">Cost per times added</div>
              </div>
              <NavLink
                data-tip
                data-for="report"
                to={`/campaign/${campaign.id}`}
                className="reactTooltip buttons campaign-buttons"
              >
                <SVG src={`${process.env.PUBLIC_URL}/media/icons/analytic.svg`} />
              </NavLink>
              <ReactTooltip type="light" className="tooltip" id="report" place="top">
                Report
              </ReactTooltip>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });
}

function MyCampaignList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { previousCampaignList, activeCampaignList, status } = useShallowEqualSelector(
    (s: StoreState) => s.campaign,
  );
  const { appList } = useShallowEqualSelector((s: StoreState) => s.app);
  useEffect(() => {
    if (status === STATUS.IDLE) {
      dispatch(getCampaigns());
      dispatch(getAppList());
    }
  }, [dispatch]);
  const redirectToCreateCampaign = () => {
    history.push('/campaign-create');
  };

  const handleClickOpenDialog = (type: string) => {
    dispatch(
      showDialog({
        type,
      }),
    );
  };

  return status === STATUS.SUCCESS ? (
    <MyAppsContainerList>
      {activeCampaignList &&
      activeCampaignList &&
      (previousCampaignList.length || activeCampaignList.length) ? (
        <div className="myAppsListContainer myCampaignListContainer">
          <div className="campaign-top">
            <div className="myAppsTitleContainer">
              <div className="myAppsTitle campaignTopBlock">
                Campaigns
                <ButtonDefault onClick={redirectToCreateCampaign} color="default" type="button">
                  Add new
                </ButtonDefault>
              </div>
              <div className="userAppsInfo">
                <div className="container">
                  <div className="info">
                    <div className="count">
                      {activeCampaignList ? activeCampaignList.length : 0}
                    </div>
                    <div className="title">active campaigns</div>
                  </div>
                  <div className="icon">
                    <SVG src={`${process.env.PUBLIC_URL}/media/icons/graphic.svg`} />
                  </div>
                </div>
              </div>
            </div>
            <div className="campaign-wrap">
              <div className="contentContainer">
                <div className="content">
                  {activeCampaignList && !!activeCampaignList.length && <p>Active campaigns</p>}
                  {CampaignList(activeCampaignList)}
                </div>
                <div className="content">
                  {previousCampaignList && !!previousCampaignList.length && (
                    <p>Previous campaigns</p>
                  )}
                  {CampaignList(previousCampaignList)}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="anyAppsContainer">
          {appList.length ? (
            <div className="anyAppsTitle">You do not have any campaigns</div>
          ) : (
            <div className="anyAppsTitle">You do not have any apps</div>
          )}
          <div className="anyAppsDescription">
            Make the most of your PWA and share it with the world through our platform.
          </div>
          {appList.length ? (
            <ButtonDefault
              className="anyAppsButton"
              onClick={redirectToCreateCampaign}
              color="default"
              type="button"
            >
              Add new
            </ButtonDefault>
          ) : (
            <ButtonDefault
              className="anyAppsButton"
              onClick={e => handleClickOpenDialog('app')}
              color="default"
              type="button"
            >
              Add new
            </ButtonDefault>
          )}
        </div>
      )}
    </MyAppsContainerList>
  ) : (
    <Loader />
  );
}

export default MyCampaignList;
