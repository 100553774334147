import React from 'react';
import SVG from 'react-inlinesvg';
import { AvField } from 'availity-reactstrap-validation';
import { getOriginalIMG } from 'pages/Campaign/List';
import { ChooseColor, StyledFileUploadV2, ToogleDefault } from 'styles';

function CampaignLook({
  handleChange,
  state,
  onChangeCampaignIcon,
  onChangeDeleteIcon,
  logo,
  app,
}) {
  return (
    <div className="stepsInfoContainer campLook">
      <div className="campaingLook">
        <h1 className="title">0.2 App and Look and feel</h1>
        <StyledFileUploadV2>
          <div className="uploadContainer">
            <label htmlFor="Featured">
              <div className="fileUploadStyle">
                <span>
                  {state.logo ? (
                    <span className="imageName" style={{ justifyContent: 'space-between' }}>
                      <span>{state.logo.name}</span>{' '}
                      <span onClick={onChangeDeleteIcon}>
                        <SVG
                          width="17px"
                          height="17px"
                          src={`${process.env.PUBLIC_URL}/media/icons/delete.svg`}
                        />
                      </span>
                    </span>
                  ) : (
                    <span className="imageName">
                      <span className="downldImage">
                        <SVG
                          width="17px"
                          height="17px"
                          src={`${process.env.PUBLIC_URL}/media/icons/upload.svg`}
                        />
                      </span>
                      <span>Select your Image</span>
                    </span>
                  )}
                </span>
              </div>
              <AvField
                type="file"
                accept=".jpg, .jpeg, .gif, .png"
                name="Featured"
                id="Featured"
                // maxFileSize={5242880}
                multiple={false}
                key={logo || ''}
                onChange={onChangeCampaignIcon}
                required={!logo}
                errorMessage="The upload image is required"
              />
            </label>
          </div>
        </StyledFileUploadV2>
        <div className="changeBannerBack">
          <div className="toogle">
            <ToogleDefault>
              <label htmlFor="cover" className="switch">
                <input
                  type="checkbox"
                  id="cover"
                  name="cover"
                  value=""
                  checked={state.cover}
                  onChange={handleChange}
                />
                <span className="slider round" />
              </label>
            </ToogleDefault>
          </div>
          <div className="toggleText">Cover</div>
        </div>
        <div className={state.cover ? 'chooseColorDisabled' : ''}>
          <div className="chooseColorText">Choose your banner background color:</div>
          <ChooseColor>
            <label htmlFor="color">
              <input
                type="color"
                value={state.color}
                id="color"
                disabled={state.cover}
                name="color"
                onChange={handleChange}
              />
              <span>
                <div className="colorPick" style={{ backgroundColor: `${state.color}` }} />
              </span>
              <span className="colorPickerLabel">
                {state.color ? `${state.color}` : 'Choose your background color'}
              </span>
            </label>
          </ChooseColor>
        </div>
      </div>

      {state.cover ? (
        <div
          className="bannerLookContainer"
          style={{
            background: `url(${logo})`,
            backgroundRepeat: 'round',
          }}
        >
          {logo && !state.cover && (
            <div className="bannerLogo">
              <img src={logo} alt={app.icon.name} />
            </div>
          )}
          <div className="bannerLookFooter">
            <div className="bannerLogoContainer">
              <div className="appIcon">
                {app.icon && (
                  <img width={20} height={20} src={getOriginalIMG(app.icon)} alt={app.icon.name} />
                )}
              </div>
              <div className="appInfo">
                <div className="appName">{app.label}</div>
                <div className="appCategory">{app.categories.name}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="bannerLookContainer"
          style={{
            background: state.color,
          }}
        >
          {logo && !state.cover && (
            <div className="bannerLogo">
              <img src={logo} alt={app.icon.name} />
            </div>
          )}
          <div className="bannerLookFooter">
            <div className="bannerLogoContainer">
              <div className="appIcon">
                {app.icon && (
                  <img width={20} height={20} src={getOriginalIMG(app.icon)} alt={app.icon.name} />
                )}
              </div>
              <div className="appInfo">
                <div className="appName">{app.label}</div>
                {app.categories && <div className="appCategory">{app.categories.name}</div>}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CampaignLook;
