import img from 'images/proPlanBg.png';
import styled from 'styled-components';

export const MainContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  .carousel {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &.fullHeight {
    height: 100vh;
  }
  .logoWrapper {
    display: block;
    margin: 20px 40px 0 40px;
  }
  .or {
    display: flex;
    text-align: center;
    .leftLine {
      margin-top: 8px;

      border-image-source: linear-gradient(270deg, rgba(231, 231, 231, 0.0001) 0%, #987bef 99.3%);
    }
    .rightLine {
      margin-top: 8px;
      svg {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
      }
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      top: 20px;
      border-image-source: linear-gradient(270deg, rgba(231, 231, 231, 0.0001) 0%, #987bef 99.3%);
    }
    .text {
      z-index: 2;
      color: #1c1939;
      display: inline-block;
      margin-bottom: 10px;
      padding: 10px;
      font-family: DM Sans;
      font-style: Medium;
      font-size: 16px;
    }
  }
  .welcomeText {
    margin: 45px auto 0 auto;
    text-align: center;
    max-width: 400px;
    height: 100%;
    h1 {
      font-family: DM Sans;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 39px;
      letter-spacing: 0;
      text-align: center;
      color: #1c1939;
    }
  }
  .welcomeImg {
    width: 360px;
    height: 322px;
    margin: 0 auto;
    display: flex;
  }
  ,
  .signTitle {
    color: #1c1939;
    margin-bottom: 40px;
    font-family: DM Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0;
    text-align: left;
    p {
      font-weight: 700;
      margin: 0;
    }
  }
  ,
  .signInCarousel {
    &.leftSide {
      display: flex;
      height: 100vh;
      .signInWrap {
        background: none;
        box-shadow: none;
        margin-top: 111px;
        h1,
        p {
          color: white;
        }
      }
      svg {
        fill: white;
      }
    }
  }
  .welcomeP {
    color: #4d5157;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.22580644488334656px;
  }
  .rightSide {
    width: 50%;
    background: #f5f5f9;
    &.publisherSide {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
    &.publisher {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .rightSideContainer {
        width: 330px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      h1 {
        margin-bottom: 15px;
      }
      .publisherDescription {
        text-align: center;
        margin-bottom: 25px;
        font-size: 14px;
        line-height: 1.5;
      }
      .termsCheck {
        margin-bottom: 15px;
      }
    }
  }
  .custom-checkbox {
    display: none;
  }
  .leftSide {
    width: 50%;
    display: flex;
    flex-direction: column;
    .logoWrapper {
      display: flex;
      align-content: center;
      .appName {
        color: white;
      }
    }
  }

  .signUp {
    margin-top: 26px;
    font-size: 14px;
    text-align: center;
    width: 100%;
  }
  .rightSideContainer {
    margin: 100px auto 0 auto;
    width: 310px;
    .forgotPassContainer {
      .forgotPass {
        margin-bottom: 0;
      }
      .forgotPassword {
        margin: 12px 5px 20px 5px;
        display: flex;
        justify-content: space-between;
        align-content: baseline;
        line-height: 0;
        .forgotLink {
          a {
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.4px;
            text-align: right;
            color: #6a52b0;
          }
        }
        label {
          font-size: 15px;
          line-height: 0;
          &:before {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .publisherSide {
    .rightSideContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: center;
      align-items: center;
      .signTitle {
        font-size: 36px;
        margin-bottom: 10px;
      }
      p {
        font-weight: 500;
        text-align: center;
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }

  display: flex;
  margin: 0 !important;
  .left-side {
    padding-left: 50px;
  }
  .right-side {
    .right-side-container {
      margin: auto;
    }
  }
  &.becomePro {
    height: 100vh;
    .logoWrapper {
      position: absolute;
      left: 0;
      top: 0;
      .appName {
        color: #ffffff;
      }
    }
    h1 {
      margin-top: 0;
    }
    .leftSide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url(${img});
    }
    .rightSide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .rightSideContainer {
        margin: 0 auto;
      }
      background: #ffffff;
    }
    .welcomeText {
      margin: 0 auto 40px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p,
      h1 {
        color: white;
      }
      p {
        margin: 0 0 27px 0;
      }
    }
    .planSummaryContainer {
      .planPayTitle {
        font-family: DM Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        color: #1c1939;
      }
      .pricingContainer {
        padding: 6px 0;
        border-bottom: 1px solid #d8d8d8;
        .pricingRow {
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;
          display: flex;
          justify-content: space-between;
          &.sales {
            font-family: DM Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            color: #4d5157;
          }
        }
      }
      .totalAnnual {
        margin-top: 5px;
        justify-content: space-between;
        display: flex;
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        color: #6a52b0;
      }
      margin-bottom: 30px;
    }
    .submitPro {
      margin-top: 10px;
    }
  }
`;
