import { all, call, put, takeLatest } from 'redux-saga/effects';

import { createRequestQuery, parseError, request } from 'modules/client';

import { ActionTypes } from 'literals';

import { BillingData } from 'types';

export function* getBillings({ payload }: any) {
  try {
    const page = payload.filter.offset + 1;
    const limit = payload.filter.limit;
    const requestQuery = createRequestQuery({ page, limit });
    const items: Promise<BillingData> = yield call(request, `/payment/list/?${requestQuery}`, {
      auth: true,
    });
    yield put({
      type: ActionTypes.BULLING_LIST_SUCCESS,
      payload: items,
    });
  } catch (message) {
    if (message === 401) {
      yield put({
        type: ActionTypes.SHOW_ALERT,
        payload: {
          message: 'Session expired, please sign in again.',
          position: 'bottom-right',
          timeout: 5,
          variant: 'warning',
          id: Math.random().toString(36).substr(2, 5),
        },
      });
      yield put({
        type: ActionTypes.USER_LOGOUT_REQUEST,
      });
    }
    const errorMessage = parseError(message.error);
    yield put({
      type: ActionTypes.BULLING_LIST_FAILURE,
      payload: errorMessage,
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: errorMessage,
        position: 'bottom-right',
        timeout: 5,
        variant: 'warning',
        id: Math.random().toString(36).substr(2, 5),
      },
    });
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.BULLING_LIST_REQUEST, getBillings)]);
}
