import { createReducer } from 'modules/helpers';

import { ActionTypes, STATUS } from 'literals';

import { BillingState } from 'types';

export const billingState: BillingState = {
  billings: [],
  pro_plan: null,
  offset: 0,
  limit: 20,
  count: 0,
  page: 0,
  total: 0,
  message: '',
  status: STATUS.IDLE,
};

export default {
  billing: createReducer<BillingState>(
    {
      [ActionTypes.BULLING_LIST_REQUEST]: (draft, { payload }) => {
        draft.status = STATUS.RUNNING;
        draft.offset = payload.filter.offset;
      },
      [ActionTypes.BULLING_LIST_SUCCESS]: (draft, { payload }) => {
        const startDate = payload.proPlan.updated_at;
        const numOfYears = 1;
        const expireDate = new Date(startDate);
        expireDate.setFullYear(expireDate.getFullYear() + numOfYears);
        expireDate.setDate(expireDate.getDate() - 1);
        draft.status = STATUS.SUCCESS;
        draft.billings = payload.campaignPaymentList.data;
        draft.count = payload.campaignPaymentList.count;
        draft.total = payload.campaignPaymentList.total;
        draft.pro_plan = payload.proPlan;
        draft.pro_plan.nextPayment = expireDate.toISOString().substr(0, 10).split('-').join('/');
      },
      [ActionTypes.BULLING_LIST_FAILURE]: draft => {
        draft.status = STATUS.ERROR;
      },
    },
    billingState,
  ),
};
