import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { sendSupport } from 'redux/actions/support';
import { DialogContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { CreateAppPopUp } from 'styles';

import { useShallowEqualSelector } from 'modules/hooks';

import { STATUS } from 'literals';

import { StoreState, SupportData } from 'types';

export default function TermsCondDialog(props) {
  const { open, onClose } = props;
  const [state, setState] = React.useState<SupportData>({
    category: '',
    subject: '',
    message: '',
  });
  const dispatch = useDispatch();
  const { status } = useShallowEqualSelector((s: StoreState) => s.support);

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.value || event.target.checked });
  };

  useEffect(() => {
    if (status === STATUS.SUCCESS) {
      onClose();
      setState({
        category: '',
        subject: '',
        message: '',
      });
    }
  }, [status]);

  const doSubmit = () => {
    dispatch(sendSupport(state));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          position: 'fixed',
          height: '100%',
          margin: '0',
          maxHeight: '90vh',
          width: '600px',
          background: '#F5F5F9',
        },
      }}
    >
      <DialogContent>
        <CreateAppPopUp
          style={{
            padding: '0 30px 0 30px',
          }}
        >
          <div className="popUpTitle">
            <div>Terms and Conditions</div>
          </div>
          <p className="popUpText">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus sed sem erat lorem mollis
            aliquet. Tincidunt et sapien blandit phasellus est nibh sed. Tincidunt urna massa sed
            est lacus. Fermentum diam tincidunt leo sodales sit massa, volutpat orci, faucibus.
            Fames enim nullam urna cras eu commodo non. Congue enim suspendisse volutpat sem id.
            Enim eu, cras purus a. Mattis pulvinar eget etiam venenatis, nullam lorem lacus,
            suspendisse. Nisl, ipsum tempus auctor integer ullamcorper in luctus. Dignissim placerat
            porta mi, enim pretium. Sollicitudin etiam semper magna neque facilisi. Egestas
            consequat commodo ut massa justo et. Fermentum feugiat ut mauris tortor. Maecenas diam
            velit, ac in aenean risus egestas. Neque, egestas tincidunt aliquet consectetur integer
            nulla quam sit aenean. Facilisis et, velit sodales eu tellus id. Erat consequat donec
            convallis ornare duis. Amet, fermentum velit sed enim facilisi. Aliquam condimentum
            lacinia magna scelerisque orci. Lorem pulvinar pulvinar morbi porta volutpat egestas
            egestas maecenas eget. Et fames ligula vivamus lobortis lorem dignissim proin. Facilisi
            risus, odio sit amet urna. Enim suspendisse ornare nec blandit scelerisque sit massa.
            Tortor, aliquet integer dolor in eget at bibendum. Risus duis sit in in eget nisi nam
            hac turpis. Praesent natoque id dapibus blandit ipsum diam accumsan tristique commodo.
            Massa sed lacus, augue lectus leo. Elementum et ipsum blandit urna tortor tincidunt nec
            lacus tristique. Sed arcu, mauris turpis elementum facilisi libero vitae at elit. Amet
            elementum facilisi cursus diam arcu, vitae sed. Dui, turpis nec condimentum arcu. Nisl
            pellentesque in arcu quam auctor dictum suspendisse suspendisse pulvinar. Volutpat
            ultricies dignissim risus eu id. Cras enim arcu cursus facilisis nisl. Habitant vitae
            lobortis lectus adipiscing congue gravida diam scelerisque eu. Iaculis netus nisi enim,
            ultricies varius. Sagittis fusce condimentum vitae proin blandit. Eros id et nam gravida
            eget ac sed felis. Ullamcorper tortor orci mauris tincidunt magna quam egestas blandit.
          </p>
          <div className="closePopUp" onClick={onClose}>
            <SVG src={`${process.env.PUBLIC_URL}/media/icons/cancel.svg`} />
          </div>
        </CreateAppPopUp>
      </DialogContent>
    </Dialog>
  );
}
