import styled from 'styled-components';
import { px } from 'styled-minimal';

import { headerHeight } from 'modules/theme';

import { UserState } from 'types';

// @ts-ignore
export const Main = styled.main<Pick<UserState, 'isAuthenticated'>>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ isAuthenticated }) => (isAuthenticated ? `${px(headerHeight)} 0 0` : 0)};

  .bannerLookContainer {
    width: 404px;
    height: 295px;
    background-repeat: round;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 15px;
    border: 1px solid #d8d8d8;
    .bannerLogo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      img {
        height: 136px;
        max-width: 235px;
      }
    }

    .bannerLookFooter {
      padding: 17px;
      align-items: center;
      border-radius: 0 0 14px 14px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.04) 0%, rgba(6, 6, 6, 0) 100%),
        rgba(0, 0, 0, 0.18);

      .bannerLogoContainer {
        display: flex;
        align-items: center;
        .appIcon {
          margin-right: 10px;
        }
        .appInfo {
          font-family: DM Sans;
          font-style: normal;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .appName {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #ffffff;
          }
          .appCategory {
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height */
            color: #ffffff;
          }
        }
      }
      display: flex;
      justify-content: space-between;
      .bannerButtons {
        button {
          font-family: DM Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          padding: 5px;
          background: #ffffff;
          margin-left: 10px;
          border-radius: 6px;
          width: 55px;
          color: #8769e1;
        }
      }
    }
  }
  .progress {
    display: flex;
    flex-direction: column;
    width: 250px;
    align-items: center;
    justify-content: center;
  }
  .additionalInfo {
    text-align: center;
    .count {
      font-family: DM Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0px;
      text-align: center;
      color: #1c1939;
    }

    .title {
      font-family: DM Sans;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0px;
      text-align: center;
      color: #1c1939;
    }
    padding: 5px 20px;
    border-right: 1px solid #b7b4d3;
  }
  .dates {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    color: #6a52b0;

    .dates-start,
    .dates-end {
      color: #4d5157;
    }
  }

  .appInfo {
    display: flex;
    align-items: center;
    max-width: 200px;
    width: 100%;
    .appImage {
      height: 64px;
      width: 64px;
      min-width: 64px;

      img {
        height: 100%;
        width: 100%;
        border-radius: 30%;
      }

      .campaign-background {
        width: 100%;
        height: 100%;
        border: 1px solid #cccccc;
      }
    }
  }

  .appTitleContainer {
    margin-left: 20px;

    .appTitle {
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      color: #1c1939;
    }

    .appCategory {
      font-family: DM Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #767b7f;
    }
  }

  .tooltip {
    font-weight: 600;
    border-radius: 40px;
    font-size: 14px;
    line-height: 18.23px;
    font-family: DM Sans;
    color: #6a52b0;
    margin-left: 0;
    -webkit-box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.15),
      -50px -50px 4px -30px rgba(255, 255, 255, 0);
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.15), -50px -50px 4px -30px rgba(255, 255, 255, 0);
  }
`;
