import { Payment } from 'redux/sagas/payment';

import { createAction } from 'modules/helpers';

import { ActionTypes } from 'literals';

export const createPayment = createAction(ActionTypes.PAYMENT_CREATE_REQUEST, (data: Payment) => ({
  data,
}));

export const createCostumer = createAction(
  ActionTypes.PAYMENT_COSTUMER_REQUEST,
  (email: string) => ({
    email,
  }),
);

export const createSubscription = createAction(
  ActionTypes.PAYMENT_SUBSCRIPTION_CREATE_REQUEST,
  (costumerId: string, priceId: string) => ({
    costumerId,
    priceId,
  }),
);

export const cancelSubscription = createAction(
  ActionTypes.PAYMENT_SUBSCRIPTION_CANCEL_REQUEST,
  (subscriptionId: string) => ({
    subscriptionId,
  }),
);

export const confirmPayment = createAction(
  ActionTypes.PAYMENT_CONFIRM_REQUEST,
  (data: Payment) => ({
    data,
  }),
);

export const errorPayment = createAction(ActionTypes.PAYMENT_FAILURE, (data: Payment) => ({
  data,
}));
