import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut } from 'redux/actions';
import styled from 'styled-components';
import { Container, Heading } from 'styled-minimal';

import Background from 'components/Background';
import { useShallowEqualSelector } from 'modules/hooks';
import { StoreState } from 'types';

const StyledContainer = styled(Container)`
  align-items: center;
  text-align: center;

  h1,
  a {
    color: #fff;
    line-height: 1;
  }

  a {
    text-decoration: underline;
  }
`;

function ServerError() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useShallowEqualSelector((s: StoreState) => s.auth);

  useEffect(() => {
    dispatch(logOut());
  }, [isAuthenticated]);
  return (
    <Background key="500" data-testid="Error">
      <StyledContainer fullScreen ySpacing>
        <Heading fontSize={100}>500</Heading>
        <Heading fontSize={100}>Server Error</Heading>
        <Link to="/">
          <Heading as="h2">go home</Heading>
        </Link>
      </StyledContainer>
    </Background>
  );
}

export default ServerError;
