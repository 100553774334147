import {
  createRequestQuery,
  PagedRequestSortOption,
  parseError,
  request,
  SelectOption,
} from 'modules/client';

import { Category } from 'types/state';

type SaveCategoryRequestDTO = Pick<Category, 'name' | 'description'>;

export class CategoryService {
  fetchCategoriesSelectOptions(): Promise<SelectOption[]> {
    const fields: (keyof Category)[] = ['id', 'name'];

    const page = 1;
    const limit = 1000;

    const sortOptions: PagedRequestSortOption<Category>[] = [{ field: 'name', order: 'asc' }];

    const requestQuery = createRequestQuery({ fields, page, limit, sortOptions });

    const category: any = request(`/category?${requestQuery}`, {
      auth: false,
    });
    return category.then(res => res.data.map(c => ({ label: c.name, id: c.id })));
  }
}
