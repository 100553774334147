import { createRequestQuery, PagedRequestSortOption, request, SelectOption } from 'modules/client';

import { AppData } from 'types';

export class AppListService {
  async fetchAppListSelectOptions(): Promise<SelectOption[]> {
    const fields: (keyof AppData)[] = ['id', 'name'];

    const page = 1;
    const limit = 1000;

    const sortOptions: PagedRequestSortOption<AppData>[] = [{ field: 'name', order: 'asc' }];

    const requestQuery = createRequestQuery({ fields, page, limit, sortOptions });

    const appList: Promise<any> = request(`/app/list?${requestQuery}`, {
      auth: true,
    });
    return appList.then(res =>
      res.data.map(c => ({
        label: c.name,
        value: c.id,
        icon: c.icon,
        categories: c.categories[0],
        campaign: c.campaign,
      })),
    );
  }
}
