/**
 * @module Sagas/GitHub
 * @desc GitHub
 */

import { all, call, put, takeLatest } from 'redux-saga/effects';

import { parseError, request } from 'modules/client';

import { ActionTypes } from 'literals';

import { UserState } from 'types';

export function* getUser() {
  try {
    const response: UserState = yield call(request, '/me?is_developer=true', {
      auth: true,
    });

    yield put({
      type: ActionTypes.GET_USER_SUCCESS,
      payload: response,
    });
  } catch (err) {
    if (err === 401) {
      yield put({
        type: ActionTypes.SHOW_ALERT,
        payload: {
          message: 'Session expired, please sign in again.',
          position: 'bottom-right',
          timeout: 5,
          id: Math.random().toString(36).substr(2, 5),
          variant: 'warning',
        },
      });
      yield put({
        type: ActionTypes.USER_LOGOUT_REQUEST,
      });
    }
    yield put({
      type: ActionTypes.GET_USER_FAILURE,
      payload: err,
      meta: { err },
    });

    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: parseError(err.message),
        position: 'bottom-right',
        timeout: 5,
        id: Math.random().toString(36).substr(2, 5),
        variant: 'warning',
      },
    });
  }
}

export function* updateUser({ payload }: any) {
  const formData = new FormData();
  Object.entries(payload.user).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value as string | File);
    }
  });

  yield put({
    type: ActionTypes.HIDE_DIALOG,
    payload: {
      type: '',
    },
  });
  try {
    const response: UserState = yield call(request, `/user/${payload.userId}`, {
      method: 'PATCH',
      auth: true,
      formData: true,
      body: formData,
    });

    yield put({
      type: ActionTypes.USER_UPDATE_SUCCESS,
      payload: response,
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'user update success',
        position: 'bottom-right',
        timeout: 5,
        id: Math.random().toString(36).substr(2, 5),
        variant: 'success',
      },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.USER_UPDATE_FAILURE,
      payload: err,
      meta: { err },
    });

    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: parseError(err.message),
        position: 'bottom-right',
        timeout: 5,
        id: Math.random().toString(36).substr(2, 5),
        variant: 'warning',
      },
    });
  }
}

export function* deleteUser({ payload }: any) {
  try {
    yield call(request, `/user/${payload.userId}`, {
      method: 'DELETE',
      auth: true,
    });
    yield put({
      type: ActionTypes.USER_DELETE_SUCCESS,
    });
    localStorage.clear();
  } catch (err) {
    yield put({
      type: ActionTypes.USER_DELETE_FAILURE,
      payload: err,
      meta: { err },
    });

    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: parseError(err.message),
        position: 'bottom-right',
        timeout: 5,
        id: Math.random().toString(36).substr(2, 5),
        variant: 'warning',
      },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_USER_REQUEST, getUser),
    takeLatest(ActionTypes.USER_UPDATE_REQUEST, updateUser),
    takeLatest(ActionTypes.USER_DELETE_REQUEST, deleteUser),
  ]);
}
