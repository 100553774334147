import { parseError } from 'modules/client';
import { createReducer } from 'modules/helpers';

import { ActionTypes, STATUS } from 'literals';

import { CampaignState } from 'types';

export const campaignState: CampaignState = {
  status: STATUS.IDLE,
  previousCampaignList: [],
  campaign: null,
  activeCampaignList: [],
  message: '',
};

export default {
  campaign: createReducer<CampaignState>(
    {
      [ActionTypes.GET_CAMPAIGN_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.GET_CAMPAIGN_SUCCESS]: (draft, { payload }) => {
        draft.status = STATUS.SUCCESS;
        draft.previousCampaignList = payload.previousCampaign;
        draft.activeCampaignList = payload.activeCampaign;
      },
      [ActionTypes.GET_CAMPAIGN_FAILURE]: (draft, { payload }) => {
        draft.message = parseError(payload);
        draft.status = STATUS.ERROR;
      },
      [ActionTypes.GET_CAMPAIGN_ID_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.GET_CAMPAIGN_ID_SUCCESS]: (draft, { payload }) => {
        draft.status = STATUS.SUCCESS;
        draft.campaign = payload;
      },
      [ActionTypes.GET_CAMPAIGN_ID_FAILURE]: (draft, { payload }) => {
        draft.message = parseError(payload);
        draft.status = STATUS.ERROR;
      },
    },
    campaignState,
  ),
};
