import { REHYDRATE } from 'redux-persist';

import { createReducer } from 'modules/helpers';

import { ActionTypes, STATUS } from 'literals';

import { AppState } from 'types';

export const appState: AppState = {
  alerts: [],
  dialog: {
    type: '',
  },
  appList: [],
  appById: null,
  status: STATUS.IDLE,
  isProPlanPage: false,
};

export default {
  app: createReducer<AppState>(
    {
      [REHYDRATE]: draft => {
        draft.alerts = [];
      },
      [ActionTypes.HIDE_ALERT]: (draft, { payload: { id } }) => {
        draft.alerts = draft.alerts.filter(d => d.id !== id);
      },
      [ActionTypes.DELETE_APP_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.IS_PRO_PLAN_APP_REQUEST]: (draft, { payload: { isProPlanPage } }) => {
        draft.isProPlanPage = isProPlanPage;
      },
      [ActionTypes.SHOW_ALERT]: (draft, { payload }) => {
        draft.alerts.push(payload);
      },
      [ActionTypes.SHOW_DIALOG]: (draft, { payload }) => {
        draft.dialog = payload;
      },
      [ActionTypes.HIDE_DIALOG]: (draft, { payload }) => {
        draft.dialog = payload;
        draft.appById = appState.appById;
      },
      [ActionTypes.CREATE_APP_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.CREATE_APP_SUCCESS]: draft => {
        draft.status = STATUS.SUCCESS;
        draft.dialog = {
          type: '',
        };
      },
      [ActionTypes.CREATE_APP_FAILURE]: draft => {
        draft.status = STATUS.ERROR;
      },
      [ActionTypes.APP_LIST_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.GET_APP_ID]: (draft, { payload: { app } }) => {
        draft.appById = {
          ...app,
        };
        draft.appById.categories = app.categories.map(c => ({ label: c.name, id: c.id }));
        draft.dialog = {
          type: 'app',
        };
      },
      [ActionTypes.UPDATE_APP_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.UPDATE_APP_SUCCESS]: draft => {
        draft.status = STATUS.SUCCESS;
        draft.dialog = {
          type: '',
        };
        draft.appById = null;
      },
      [ActionTypes.UPDATE_APP_FAILURE]: draft => {
        draft.status = STATUS.ERROR;
      },
      [ActionTypes.APP_LIST_SUCCESS]: (draft, { payload }) => {
        draft.appList = [];
        draft.status = STATUS.SUCCESS;
        draft.appList = payload.data;
        draft.dialog = {
          type: '',
        };
        draft.appById = null;
      },
      [ActionTypes.APP_LIST_FAILURE]: draft => {
        draft.status = STATUS.ERROR;
      },
    },
    appState,
  ),
};
