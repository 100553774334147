import React from 'react';
import { DialogContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import UserForm from 'components/UserForm';
import { Transition } from 'components/AppDialog';

export default function UserDialog(props) {
  const { open, onClose } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      // @ts-ignore
      TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          position: 'fixed',
          right: '0',
          height: '100vh',
          margin: '0',
          maxHeight: '100vh',
          width: '400px',
          padding: '0 30px 0 30px',
          background: '#F5F5F9',
        },
      }}
    >
      <DialogContent>
        <UserForm onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
}
