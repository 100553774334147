import styled from 'styled-components';

export const fileUpload = styled.div`
  label {
    color: red;
  }
  input {
    display: none;
  }
`;
export const StyledFileUpload = styled.div`
  margin-bottom: 15px;
  position: relative;
  .fileUploadStyle {
    margin-right: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 115px;
    width: 115px;
    background: #ffffff;
    border: 2px dashed #6a52b0;
    border-radius: 35px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  .uploadContainer {
    display: flex;
    .uploadDescriptionContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .uploadTitle {
        margin-bottom: 6px;
        font-family: DM Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        text-align: left;
        line-height: 16px;
        color: #6a52b0;
      }
      .uploadDescription {
        font-family: DM Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.19354838132858276px;
        text-align: left;
        color: #767b7f;
      }
    }
  }
  input {
    display: none;
  }

  .uploadImageContainer {
    .uploadImage {
      position: relative;
      .closeIcon {
        cursor: pointer;
        position: absolute;
        top: -4px;
        right: -4px;
        svg {
          fill: red;
          width: 10px;
          height: 10px;
        }
      }
      img {
        border-radius: 35px;
        background: black;
      }
    }
    top: 0;
    position: absolute;
  }
`;

export const StyledFileUploadV2 = styled.div`
  width: 300px;
  margin-top: 20px;
  .fileUploadStyle {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #6a52b0;
    border-radius: 12px;
  }

  .text-danger {
    color: red;
    font-size: 12px;
    text-align: left;
    margin-top: 5px;
  }

  .imageName {
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.29032257199287415px;
    text-align: left;
    color: #6a52b0;
    display: flex;
    justify-content: center;
    .downldImage {
      margin-right: 5px;
    }
  }
  background: #ffffff;
  text-align: center;
  input {
    display: none;
  }
`;
