import React, { useEffect, useRef, useState } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

function Graphic({ campaign }) {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  const chartConfig = {
    type: 'line',
    data: {
      labels: [
        campaign.start_date.toString().substr(0, 10).split('-').join('/'),
        campaign.finish_date.toString().substr(0, 10).split('-').join('/'),
      ],
      datasets: [
        {
          axis: 'y',
          label: '# Impressions',
          data: [0, campaign.impressions],
          backgroundColor: ['#6A52B0'],
          borderColor: ['#6A52B0'],
          borderWidth: 1,
        },
        {
          axis: 'y',
          label: '# Times Added',
          data: [0, campaign.app.installs.length],
          backgroundColor: ['#1d0367'],
          borderColor: ['#1d0367'],
          borderWidth: 1,
        },
      ],
    },
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
  };
  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      // @ts-ignore
      const newChartInstance = new Chart(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  return (
    <div>
      <canvas ref={chartContainer} />
    </div>
  );
}

export default Graphic;
