import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { sendSupport } from 'redux/actions/support';
import { DialogContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { ButtonDefault, CreateAppPopUp, InputDefault } from 'styles';

import { useShallowEqualSelector } from 'modules/hooks';

import { STATUS } from 'literals';

import { Transition } from 'components/AppDialog';

import { StoreState, SupportData } from 'types';

export default function SupportDialog(props) {
  const { open, onClose } = props;
  const [state, setState] = React.useState<SupportData>({
    category: '',
    subject: '',
    message: '',
  });
  const dispatch = useDispatch();
  const { status } = useShallowEqualSelector((s: StoreState) => s.support);

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (status === STATUS.SUCCESS) {
      onClose();
      setState({
        category: '',
        subject: '',
        message: '',
      });
    }
  }, [status]);

  const doSubmit = () => {
    dispatch(sendSupport(state));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      // @ts-ignore
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          position: 'fixed',
          right: '0',
          height: '100vh',
          margin: '0',
          maxHeight: '100vh',
          width: '400px',
          padding: '30px',
          background: '#F5F5F9',
        },
      }}
    >
      <DialogContent
        style={{
          padding: 0,
        }}
      >
        <CreateAppPopUp>
          <div className="popUpTitle">
            <div>Support</div>
          </div>
          <AvForm className="signupForm" onValidSubmit={doSubmit} model={state}>
            <div className="userName">
              <InputDefault>
                <AvField
                  name="subject"
                  required
                  errorMessage="The subject field is required"
                  type="string"
                  className="form-control"
                  id="subject"
                  aria-describedby="subject"
                  placeholder="subject"
                  value={state.subject}
                  onChange={handleChange}
                />
              </InputDefault>
            </div>

            <InputDefault className="textAreaField">
              <AvField
                name="message"
                required
                errorMessage="The message field is required"
                type="textarea"
                className="form-control"
                id="message"
                aria-describedby="message"
                placeholder="message"
                value={state.message}
                onChange={handleChange}
              />
            </InputDefault>
            <ButtonDefault
              className="default"
              color="default"
              type="submit"
              disabled={status === STATUS.RUNNING}
              style={{
                width: '100%',
              }}
            >
              <div className="buttonContainer">
                <div className="buttonName">Send a message</div>
              </div>
            </ButtonDefault>
          </AvForm>
          <div className="closePopUp" onClick={onClose}>
            <SVG src={`${process.env.PUBLIC_URL}/media/icons/cancel.svg`} />
          </div>
        </CreateAppPopUp>
      </DialogContent>
    </Dialog>
  );
}
