import { createAction } from 'modules/helpers';

import { ActionTypes } from 'literals';

export const sendSupport = createAction(
  ActionTypes.SUPPORT_POST_REQUEST,
  ({ category, subject, message }) => ({
    category,
    subject,
    message,
  }),
);
