import React from 'react';
import SVG from 'react-inlinesvg';
import ReactTooltip from 'react-tooltip';
import { AvField } from 'availity-reactstrap-validation';
import { CheckBoxStyled, InputDefault } from 'styles';

function CampaignDetail({ handleChange, state, appList }) {
  return (
    <div className="stepsInfoContainer">
      <div>
        <h1 className="title">0.1 Campaign details</h1>
        <div>
          <InputDefault className="selectField">
            <AvField
              className="form-control"
              type="select"
              label="Select your app"
              onChange={e => handleChange(e)}
              id="app"
              name="app"
              required
              errorMessage="The app is required"
            >
              <option value="">select your app</option>
              {appList.length &&
                appList.map(app => (
                  <option disabled={app.campaign} key={app.value} value={app.value}>
                    {app.label}
                  </option>
                ))}
            </AvField>
          </InputDefault>
          <div className="campaignType">
            <CheckBoxStyled>
              <div className="checkbox">
                <input
                  className="custom-checkbox"
                  type="checkbox"
                  id="banner"
                  name="banner"
                  value={null}
                  checked={state.banner}
                  onChange={handleChange}
                />
                <label htmlFor="banner">Banners</label>
              </div>
            </CheckBoxStyled>
            <span data-tip data-for="banners" className="svgToolTip">
              <SVG src={`${process.env.PUBLIC_URL}/media/icons/campaing-tool-tip.svg`} />
            </span>
            <ReactTooltip
              className="tooltip"
              textColor="#6A52B0"
              type="light"
              id="banners"
              place="top"
              effect="solid"
            >
              Cost per day $80
            </ReactTooltip>
          </div>
          <div className="campaignType">
            <CheckBoxStyled>
              <div className="checkbox">
                <input
                  className="custom-checkbox"
                  type="checkbox"
                  id="search_result"
                  name="search_result"
                  value={null}
                  checked={state.search_result}
                  onChange={handleChange}
                />
                <label htmlFor="search_result">Search results</label>
              </div>
            </CheckBoxStyled>
            <span data-tip data-for="searchResult" className="svgToolTip">
              <SVG src={`${process.env.PUBLIC_URL}/media/icons/campaing-tool-tip.svg`} />
            </span>
            <ReactTooltip
              className="tooltip"
              textColor="#6A52B0"
              type="light"
              id="searchResult"
              place="top"
              effect="solid"
            >
              Cost per day $40
            </ReactTooltip>
          </div>
          <div className="campaignType">
            <CheckBoxStyled>
              <div className="checkbox">
                <input
                  className="custom-checkbox"
                  type="checkbox"
                  id="news_letter"
                  name="news_letter"
                  value={null}
                  checked={state.news_letter}
                  onChange={handleChange}
                />
                <label htmlFor="news_letter">Newsletter campaign</label>
              </div>
            </CheckBoxStyled>
            <span data-tip data-for="newsLetter" className="svgToolTip">
              <SVG src={`${process.env.PUBLIC_URL}/media/icons/campaing-tool-tip.svg`} />
            </span>
            <ReactTooltip
              className="tooltip"
              textColor="#6A52B0"
              type="light"
              id="newsLetter"
              place="top"
              effect="solid"
            >
              Cost per day $1000
            </ReactTooltip>
          </div>
          <div className="timeline">Enter the timeframe you want the add to run:</div>

          <div className="dateSelect">
            <div className="dateSelectText">Start date</div>
            <InputDefault className="dateSelectForm">
              <AvField
                name="start_date"
                required={!state.news_letter}
                disabled={state.news_letter && !state.banner && !state.search_result}
                errorMessage="Start date is required"
                className="form-control"
                id="start_date"
                defaultValue={state.start_date}
                onChange={handleChange}
                placeholder="start_date"
                type="date"
              />
            </InputDefault>
          </div>
          <div className="dateSelect">
            <div className="dateSelectText">End date</div>
            <InputDefault>
              <AvField
                name="finish_date"
                required={!state.news_letter}
                disabled={state.news_letter && !state.banner && !state.search_result}
                errorMessage="End date is required"
                className="form-control"
                id="finish_date"
                onChange={handleChange}
                defaultValue={state.finish_date}
                placeholder="finish_date"
                type="date"
              />
            </InputDefault>
          </div>

          <div className="campaignCost">
            <div className="campaignCostText">Campaign Cost</div>
            <div className="campaignCostCalculate">${state.amount}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignDetail;
