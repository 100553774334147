import React, { Component, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { login, loginUserWithAccessToken } from 'redux/actions';
import { Loader } from 'styled-minimal';

import { useShallowEqualSelector } from 'modules/hooks';

import { StoreState } from 'types';

function LoginSuccessPage() {
  const [redirectPath, setRedirectPath] = useState(null);
  const dispatch = useDispatch();
  const { user } = useShallowEqualSelector((s: StoreState) => s.user);

  useEffect(() => {
    const { token } = Object.fromEntries(new URLSearchParams(window.location.search)) || {};

    if (token) {
      dispatch(
        loginUserWithAccessToken({
          accessToken: token,
        }),
      );
    } else {
      setRedirectPath({
        redirectPath: '/404',
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setRedirectPath('/');
    }
    if (redirectPath) {
      <Redirect to={redirectPath} />;
    }
  }, [user, redirectPath]);

  return (
    <div>
      <h1>Signing in...</h1>
      <Loader />
    </div>
  );
}

export default LoginSuccessPage;
