import styled from 'styled-components';
import proPlanBg from 'images/proPlanCardBg.png';

export const Card = styled.div`
  width: 260px;
  border: 0.9px solid #dfe1e5;
  border-radius: 15px;
  .pricingItemTitle {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 0px;
      text-align: left;
    }
    .pricingPlan {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0;
      text-align: left;
    }
    &.withBg {
      background-image: url(${proPlanBg});
      background-size: cover;
      color: #fff;
    }

    border-radius: 15px 15px 0 0;
    padding: 14px 20px 8px 16px;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #1c1939;
  }
  .pricingItemContent {
    background: #ffffff;
    padding: 30px 26px 30px 16px;
    border-radius: 0 0 13px 13px;
  }
  .custom-checkbox:checked + label::before {
    border-color: #38bfa1 !important;
    background-color: #38bfa1 !important;
  }
  .pricingItemContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    .pricingColumn {
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 0;
      letter-spacing: 0;
      text-align: left;
      color: #767b7f;
      &.unlimited {
        font-weight: 700;
        text-align: right;
        color: #38bfa1;
      }
    }
  }
`;
