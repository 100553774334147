import styled from 'styled-components';

interface StyledAuthProps {
  color?: string;
  background?: string;
}

const handleColorType = (color: string | undefined) => {
  switch (color) {
    case 'primary':
      return '#03a9f3';
    case 'black':
      return '#1C1939';
    case 'red':
      return '#F26161';
    default:
      return '#fff';
  }
};

const handleBackroundType = (background: string | undefined) => {
  switch (background) {
    case 'facebook':
      return '#1877F2';
    case 'google':
      return '#FFFFFF';
    case 'transparent':
      return 'transparent';
    default:
      return '#6A52B0';
  }
};

export const ButtonDefault = styled.button<StyledAuthProps>`
  width: 310px;
  padding: 9px;
  margin-bottom: 16px;
  color: ${({ color }) => handleColorType(color)};
  background: ${({ background }) => handleBackroundType(background)};
  border: 1px solid #d3d3d3;
  border-radius: 12px;
  font-size: 14px;
  &:hover {
    background-color: #553aa3;
  }
  :disabled {
    background-color: #dfdfdf;
    cursor: no-drop;
  }

  &.facebook {
    &:hover {
      background-color: #0d63d3;
    }
  }

  &.google {
    &:hover {
      background-color: #f2f0f8;
    }
  }

  .buttonContainer {
    position: relative;
    .buttonIcon {
      position: absolute;
      left: 0;
      bottom: -4px;
    }
    .buttonName {
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.19354838132858276px;
    }
  }
`;
