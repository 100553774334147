import { createAction } from 'modules/helpers';

import { ActionTypes } from 'literals';

import { UserData } from 'types';

export const getUser = createAction(ActionTypes.GET_USER_REQUEST, () => ({}));
export const updateUser = createAction(
  ActionTypes.USER_UPDATE_REQUEST,
  (user: UserData, userId: number) => ({ user, userId }),
);

export const deleteUser = createAction(ActionTypes.USER_DELETE_REQUEST, (userId: number) => ({
  userId,
}));
