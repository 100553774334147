import React, { useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logOut, showDialog } from 'redux/actions';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';

import { useShallowEqualSelector } from 'modules/hooks';

import { HeaderContainer, HeaderWrapper } from 'components/Header/Style';
import Logo from 'components/Logo';

import { StoreState } from 'types';

export default function Header() {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClickOpenDialog = (type: string) => {
    dispatch(
      showDialog({
        type,
      }),
    );
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  const { user, status } = useShallowEqualSelector((s: StoreState) => s.user);
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const handleClickLogout = () => {
    dispatch(logOut());
  };

  return (
    <HeaderWrapper data-testid="Header">
      <HeaderContainer>
        <div className="navigationContainer">
          <Logo type="home" />
          <div className="navigationLinkContainer">
            <NavLink activeClassName="active" exact={true} className="navigationLink" to="/">
              My Apps
            </NavLink>
          </div>
          <div className="navigationLinkContainer">
            <NavLink activeClassName="active" className="navigationLink" to="/campaign">
              Campaigns
            </NavLink>
          </div>
          <div onClick={e => handleClickOpenDialog('support')} className="navigationLinkContainer">
            <a className="navigationLink">Support</a>
          </div>
          <div className="navigationLinkContainer">
            <NavLink
              activeClassName="active"
              className="navigationLink"
              to={{ pathname: 'https://weshareapps.com' }}
              target="_blank"
            >
              WSA Website
            </NavLink>
          </div>
        </div>
        <div className="headerButtons">
          <div className="headerSvgContainer" onClick={e => handleClickOpenDialog('app')}>
            <SVG className="headerSvg" src={`${process.env.PUBLIC_URL}/media/icons/upload.svg`} />
          </div>
          <div
            className="headerSvgContainer"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <SVG className="headerSvg" src={`${process.env.PUBLIC_URL}/media/icons/profile.svg`} />
          </div>
        </div>
      </HeaderContainer>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              width: '124px',
              borderRadius: '8px',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={e => handleClickOpenDialog('user')}>My account</MenuItem>
                  {user && user.pro_plan && (
                    <MenuItem onClick={e => handleClickOpenDialog('billing')}>Billing</MenuItem>
                  )}
                  <MenuItem onClick={handleClickLogout}>
                    <SVG
                      className="headerSvg"
                      src={`${process.env.PUBLIC_URL}/media/icons/logout.svg`}
                    />
                    &nbsp;&nbsp;Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </HeaderWrapper>
  );
}
