import React, { useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { createPayment } from 'redux/actions';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AvForm } from 'availity-reactstrap-validation';
import clsx from 'clsx';
import { useApiService } from 'hooks/useApiService';
import { useEffectOnMount } from 'hooks/useEffectOnMount';
import { CampaingContainer } from 'pages/Campaign/create/Style';
import CampaignDetail from 'pages/Campaign/steps/campaignDetail';
import CampaignLook from 'pages/Campaign/steps/campaignLook';
import CampaignPay from 'pages/Campaign/steps/campaignPay';
import PropTypes from 'prop-types';

import { SelectOption } from 'modules/client';
import { useShallowEqualSelector } from 'modules/hooks';

import { STATUS } from 'literals';

import NewsLetterDialog from 'components/NewsLetterDialog';

import { StoreState } from 'types';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {/* {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}*/}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    zIndex: 1,
    color: '#fff',
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
  },
  active: {
    background: '#fff',
    border: '1px solid #6A52B0',
  },
  completed: {
    background: '#38BFA1',
  },
});

function ColorlibStepIcon({ icon, active, completed }) {
  const classes = useColorlibStepIconStyles();

  const icons = {
    1: <SVG src={`${process.env.PUBLIC_URL}/media/icons/check.svg`} />,
    2: <SVG src={`${process.env.PUBLIC_URL}/media/icons/check.svg`} />,
    3: <SVG src={`${process.env.PUBLIC_URL}/media/icons/check.svg`} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['01. Campaign Details', '02. App and Look and feel', '03. Review and Pay'];
}

function getStepContent(
  step,
  handleChange,
  state,
  onChangeCampaignIcon,
  logo,
  appList,
  onDeleteIcon,
) {
  let app = {
    categories: null,
    label: null,
    icon: null,
  };

  if (appList.length && state.app) {
    app = appList.find(x => x.value === +state.app) || null;
  }
  switch (step) {
    case 0:
      return <CampaignDetail appList={appList} handleChange={handleChange} state={state} />;
    case 1:
      return (
        <CampaignLook
          logo={logo}
          onChangeCampaignIcon={onChangeCampaignIcon}
          onChangeDeleteIcon={onDeleteIcon}
          handleChange={handleChange}
          state={state}
          app={app}
        />
      );
    case 2:
      return <CampaignPay handleChange={handleChange} app={app} state={state} />;
    default:
      return 'Unknown step';
  }
}

export default function CampaignForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [state, setState] = React.useState({
    cover: false,
    banner: false,
    search_result: false,
    news_letter: false,
    app: null,
    loading: false,
    start_date: null,
    finish_date: null,
    color: '#ffffff',
    type: 'campaign',
    amount: 0,
    logo: null,
    email: '',
    name: '',
  });
  const [open, setOpen] = useState(false);
  const [appList, setAppList] = useState<SelectOption[]>([]);
  const { status } = useShallowEqualSelector((s: StoreState) => s.payment);

  const [fetchAppList, { isLoading: isFetchingCategories }] = useApiService(
    'appList',
    appListService => () => appListService.fetchAppListSelectOptions().then(rec => setAppList(rec)),
  );

  useEffectOnMount(() => {
    fetchAppList();
  });

  const [logo, setPicture] = useState('');

  const steps = getSteps();

  const handleNext = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (activeStep === 2) {
      dispatch(createPayment(state));
      return;
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const daysBetween = (date1, date2) => {
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const differenceMs = Math.abs(date1 - date2);

    return Math.ceil(differenceMs / ONE_DAY) + 1;
  };

  const handleChange = event => {
    const { name, checked, value } = event.target;
    setState({ ...state, [name]: value || checked });
    if (name === 'news_letter') {
      const news_letter = 1000;
      if (checked) {
        setOpen(true);

        setState(prevState => ({
          ...prevState,
          amount: state.amount + news_letter,
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          amount: state.amount - news_letter,
        }));
      }
    }
  };

  useEffect(() => {
    if (state.start_date && state.finish_date) {
      setState({
        ...state,
        amount: 0,
      });
      const banner = state.banner ? 80 : 0;
      const search_result = state.search_result ? 40 : 0;
      setState({
        ...state,
        amount:
          (banner + search_result) *
          daysBetween(new Date(state.start_date), new Date(state.finish_date)),
      });
    }
  }, [state.start_date, state.finish_date, state.banner, state.search_result]);

  const onChangeCampaignIcon = e => {
    if (e.target.files[0]) {
      setState(prevState => ({
        ...prevState,
        logo: e.target.files[0],
      }));
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPicture(reader.result.toString());
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onDeleteIcon = e => {
    setState(prevState => ({
      ...prevState,
      logo: null,
    }));
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const avForm = useRef<{ reset: any }>();

  return (
    <CampaingContainer>
      <div className="stepper">
        <div className="stepsTitle">Steps</div>
        <Stepper
          orientation="vertical"
          className="Testing"
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <div className="stepContent">
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <AvForm ref={avForm} onValidSubmit={handleNext} model={state}>
            <div>
              {getStepContent(
                activeStep,
                handleChange,
                state,
                onChangeCampaignIcon,
                logo,
                appList,
                onDeleteIcon,
              )}
            </div>
            <div>
              {status !== STATUS.RUNNING && status !== STATUS.CONTINUE && (
                <Button variant="contained" color="primary" type="submit" className="nextStep">
                  {activeStep === steps.length - 1 ? `Pay $${state.amount}` : 'Next'}
                </Button>
              )}
              {(status === STATUS.RUNNING || status === STATUS.CONTINUE) && (
                <Button
                  disabled={true}
                  variant="contained"
                  color="primary"
                  type="button"
                  className="nextStep"
                >
                  {STATUS.RUNNING}
                </Button>
              )}
            </div>
          </AvForm>
        )}
        <NewsLetterDialog open={open} onClose={handleClose} />
      </div>
    </CampaingContainer>
  );
}
