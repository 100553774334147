import { createReducer } from 'modules/helpers';

import { ActionTypes, STATUS } from 'literals';

import { UserState } from 'types';

export const userState: UserState = {
  user: null,
  status: STATUS.IDLE,
};

export default {
  user: createReducer<UserState>(
    {
      [ActionTypes.GET_USER_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.GET_USER_SUCCESS]: (draft, { payload }) => {
        draft.status = STATUS.SUCCESS;
        draft.user = payload;
      },
      [ActionTypes.GET_USER_FAILURE]: draft => {
        draft.status = STATUS.ERROR;
      },
      [ActionTypes.USER_UPDATE_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.USER_UPDATE_SUCCESS]: (draft, { payload }) => {
        draft.status = STATUS.SUCCESS;
        draft.user = payload;
      },
      [ActionTypes.USER_UPDATE_FAILURE]: draft => {
        draft.status = STATUS.ERROR;
      },
      [ActionTypes.USER_DELETE_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.USER_DELETE_SUCCESS]: draft => {
        draft.status = STATUS.SUCCESS;
        draft.user = null;
      },
      [ActionTypes.USER_DELETE_FAILURE]: draft => {
        draft.status = STATUS.ERROR;
      },
    },
    userState,
  ),
};
