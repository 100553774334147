import styled from 'styled-components';

export const CheckBoxDefault = styled.div`
  .checkbox-button {
    cursor: pointer;
  }

  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }
  &.conditions {
    margin-bottom: 16px;
  }
  input {
    font-size: 1rem;
    line-height: 1.5;
    padding: 11px 23px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    outline: 0;
    background-color: transparent;
  }

  .checkbox-button__input {
    opacity: 0;
    position: absolute;
  }

  .checkbox-button__control {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 12px;
    vertical-align: middle;
    background-color: inherit;

    border: 1px solid #dedede;
    border-radius: 8px;
  }

  .checkbox-button__control:before {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 16px;
    height: 16px;
    background-color: #dedede;
    border-radius: 5px;
  }
  .checkbox-button__label {
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  .link {
    color: #8769e1;
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
  .checkbox-button__input:checked + .checkbox-button__control:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 16px;
    height: 16px;
    background-color: #8769e1;
    border-radius: 5px;
  }

  .checkbox-button__control {
    transform: scale(0.75);
  }
`;

export const ToogleDefault = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 32px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #38bfa1;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    left: -2px;
    bottom: 4px;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const CheckBoxStyled = styled.div`
  /* для элемента input c type="checkbox" */
  .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: none;
  }

  /* для элемента label, связанного с .custom-checkbox */
  .custom-checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    outline: none;
    cursor: pointer;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.29032257199287415px;
    text-align: left;
    color: #4d5157;
  }

  /* создание в label псевдоэлемента before со следующими стилями */
  .custom-checkbox + label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }

  /* стили при наведении курсора на checkbox */
  .custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
    border-color: #b3d7ff;
  }

  /* стили для активного чекбокса (при нажатии на него) */
  .custom-checkbox:not(:disabled):active + label::before {
    background-color: #8769e1;
    border-color: #b3d7ff;
  }

  /* стили для чекбокса, находящегося в фокусе */
  .custom-checkbox:focus + label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
  .custom-checkbox:focus:not(:checked) + label::before {
    border-color: #80bdff;
  }

  /* стили для чекбокса, находящегося в состоянии checked */
  .custom-checkbox:checked + label::before {
    border-color: #8769e1;
    background-color: #8769e1;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  /* стили для чекбокса, находящегося в состоянии disabled */
  .custom-checkbox:disabled + label::before {
    background-color: #8769e1;
  }

  body {
    padding: 20px 50px;
  }
`;
