import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { confirmPayment, errorPayment } from 'redux/actions';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { AvField } from 'availity-reactstrap-validation';
import { PaymentStyled } from 'styles/payment';

import { useShallowEqualSelector } from 'modules/hooks';

import { STATUS } from 'literals';

import { ErrorMessage } from 'components/Payment/ErrorMessage';

import { StoreState } from 'types';

export function CheckoutForm({ state, handleChange }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { client_secret, status, subscriptionId, costumerId } = useShallowEqualSelector(
    (s: StoreState) => s.payment,
  );
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (status === STATUS.CONTINUE) {
      stripe
        .confirmCardPayment(client_secret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: state.name,
              email: state.email,
            },
          },
          receipt_email: state.email,
        })
        .then((payload: PaymentIntentResult) => {
          if (payload.error) {
            setError(payload.error);
            dispatch(errorPayment(payload.error));
          } else {
            dispatch(
              confirmPayment({
                paymentId: payload.paymentIntent.id,
                subscriptionId,
                costumerId,
                ...state,
              }),
            );
            history.push('/payment');
          }
        });
    }
  }, [status]);

  return (
    <PaymentStyled>
      <AvField
        label="Name"
        id="name"
        name="name"
        type="text"
        placeholder="Jane Doe"
        required
        errorMessage="The name is required"
        autoComplete="name"
        value={state.name}
        onChange={handleChange}
      />
      <AvField
        label="Email"
        id="email"
        type="email"
        name="email"
        placeholder="janedoe@gmail.com"
        required
        errorMessage="The email is required"
        autoComplete="email"
        value={state.email}
        onChange={handleChange}
      />
      <CardElement
        className="card"
        options={{
          style: {
            base: {
              backgroundColor: 'white',
            },
          },
        }}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </PaymentStyled>
  );
}
