/**
 * @module Sagas/Auth
 * @desc Auth
 */

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { parseError, request } from 'modules/client';
import { push } from 'modules/history';

import { ActionTypes } from 'literals';

/**
 * Login
 */

export interface Login {
  email: string;
  password: string;
  confirmRole?: 'false' | 'true';
}

export interface Register {
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  password: string;
  conditions?: boolean;
}
export function* login({ payload }: any) {
  const { email, password, confirmRole }: Login = payload;
  try {
    const items: Promise<Login> = yield call(request, '/auth/login', {
      method: 'POST',
      body: {
        email_username: email,
        password,
      },
      headers: {
        'Develop-Role': 'true',
        'Confirm-Role': confirmRole,
      },
    });
    yield put({
      type: ActionTypes.USER_LOGIN_SUCCESS,
      payload: items,
    });
  } catch (message) {
    const errorMessage = parseError(message.error);
    if (errorMessage && errorMessage === 'developer') {
      yield put({
        type: ActionTypes.USER_LOGIN_CONTINUE,
        payload,
      });
    } else {
      yield put({
        type: ActionTypes.USER_LOGIN_FAILURE,
        payload: errorMessage,
        meta: { payload },
      });
      yield put({
        type: ActionTypes.SHOW_ALERT,
        payload: {
          message:
            errorMessage === 'Something went wrong'
              ? 'user email or password not correct'
              : errorMessage,
          position: 'bottom-right',
          timeout: 5,
          variant: 'warning',
          id: Math.random().toString(36).substr(2, 5),
        },
      });
      localStorage.clear();
    }
  }
}

export function* forgotPassword({ payload }: any) {
  const { email }: Login = payload;
  try {
    const items: Promise<Login> = yield call(request, '/auth/recovery', {
      method: 'POST',
      body: {
        email,
      },
    });
    yield put({
      type: ActionTypes.FORGOT_PASSWORD_SUCCESS,
      payload: items,
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'recovery success',
        position: 'bottom-right',
        timeout: 5,
        variant: 'success',
        id: Math.random().toString(36).substr(2, 5),
      },
    });
    push('/login');
  } catch (error) {
    yield put({
      type: ActionTypes.FORGOT_PASSWORD_FAILURE,
      payload: error,
      meta: { payload },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: error.message,
        position: 'bottom-right',
        timeout: 5,
        variant: 'warning',
        id: Math.random().toString(36).substr(2, 5),
      },
    });
  }
}

export function* socialAuth({ payload }: any) {
  try {
    yield put({
      type: ActionTypes.SOCIAL_AUTH_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.SOCIAL_AUTH_FAILURE,
      payload: error,
      meta: { payload },
    });
  }
}

export function* register({ payload }: any) {
  const { email, password, first_name, last_name, username }: Register = payload;
  try {
    const items: Promise<Login> = yield call(request, '/user/signup', {
      method: 'POST',
      headers: {
        'Develop-Role': 'true',
      },
      body: {
        email,
        repeat_password: password,
        password,
        first_name,
        last_name,
        username,
      },
    });
    yield put({
      type: ActionTypes.USER_REGISTER_SUCCESS,
      payload: items,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.USER_REGISTER_FAILURE,
      payload: err.message,
      meta: { payload },
    });
    const messages = parseError(err.message);
    if (Array.isArray(messages)) {
      for (const message of messages) {
        yield put({
          type: ActionTypes.SHOW_ALERT,
          payload: {
            message,
            position: 'bottom-right',
            timeout: 5,
            variant: 'warning',
            id: Math.random().toString(36).substr(2, 5),
          },
        });
      }
    } else {
      yield put({
        type: ActionTypes.SHOW_ALERT,
        payload: {
          message: messages,
          position: 'bottom-right',
          timeout: 5,
          variant: 'warning',
          id: Math.random().toString(36).substr(2, 5),
        },
      });
    }
  }
}

/**
 * Logout
 */
export function* logout() {
  yield put({
    type: ActionTypes.USER_LOGOUT_SUCCESS,
  });
}

/**
 * User Sagas
 */
export default function* root() {
  yield all([
    takeLatest(ActionTypes.USER_LOGIN_REQUEST, login),
    takeLatest(ActionTypes.USER_REGISTER_REQUEST, register),
    takeLatest(ActionTypes.USER_LOGOUT_REQUEST, logout),
    takeLatest(ActionTypes.FORGOT_PASSWORD_REQUEST, forgotPassword),
    takeLatest(ActionTypes.SOCIAL_AUTH_REQUEST, socialAuth),
  ]);
}
