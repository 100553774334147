import React from 'react';
import styled from 'styled-components';
import { Alert as AlertComponent, Box } from 'styled-minimal';
import { Variants } from 'styled-minimal/lib/types';

import { spacer, variants } from 'modules/theme';

import Icon from 'components/Icon';

import { Icons } from 'types';

interface Props {
  children: React.ReactNode;
  handleClickClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: Icons;
  id?: string;
  variant?: string;
}

AlertComponent.displayName = 'AlertComponent';

const AlertIcon = styled.div`
  align-items: flex-start;
  background-color: ${({ variant }: { variant: Variants }) => variants[variant]};
  color: #fff;
  display: flex;
`;

const AlertButton = styled.button`
  background-color: ${({ variant }: { variant: Variants }) => variants[variant]};
  color: #fff;
  display: none;
  line-height: 0;
  pointer-events: all;
  position: absolute;
  right: ${spacer(1)};
  top: ${spacer(1)};
`;

function Alert({ children, handleClickClose, id, icon, variant = 'gray', ...rest }: Props) {
  const output: Record<string, any> = {};
  let name: Icons;

  switch (variant) {
    case 'success': {
      name = icon || 'success';
      break;
    }
    case 'warning': {
      name = icon || 'warning';
      break;
    }
    case 'danger': {
      name = icon || 'warning';
      break;
    }
    case 'info': {
      name = icon || 'question-circle';
      break;
    }
    case 'dark': {
      name = icon || 'bell-o';
      break;
    }
    default: {
      name = icon || 'dot-circle-o';
    }
  }

  if (handleClickClose) {
    output.button = (
      <AlertButton key={id} data-id={id} onClick={handleClickClose} type="button" variant={variant}>
        <Icon name="times" width={10} />
      </AlertButton>
    );
  }

  return (
    <AlertComponent
      {...rest}
      alignItems="center"
      data-testid="Alert"
      display="flex"
      position="relative"
      variant={variant}
      key={name}
      className="alert"
    >
      <AlertIcon className="alert-icon" variant={variant} {...rest}>
        <Icon name={name} width={24} />
      </AlertIcon>
      <div className="alert-message">
        <span className="alert-title">{variant}</span>
        <span className="alert-description">{children}</span>
      </div>
      {output.button}
    </AlertComponent>
  );
}

export default Alert;
