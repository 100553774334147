import { all, call, put, takeLatest } from 'redux-saga/effects';

import { parseError, request } from 'modules/client';

import { ActionTypes } from 'literals';

import { SupportData } from 'types';

export function* postSupport({ payload }: any) {
  try {
    const items: Promise<SupportData> = yield call(request, `/support`, {
      auth: true,
      method: 'POST',
      body: payload,
    });
    yield put({
      type: ActionTypes.SUPPORT_POST_SUCCESS,
      payload: items,
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'your message success send',
        position: 'bottom-right',
        timeout: 5,
        id: Math.random().toString(36).substr(2, 5),
        variant: 'success',
      },
    });
  } catch (message) {
    yield put({
      type: ActionTypes.SUPPORT_POST_FAILURE,
    });
    if (message === 401) {
      yield put({
        type: ActionTypes.SHOW_ALERT,
        payload: {
          message: 'Session expired, please sign in again.',
          position: 'bottom-right',
          timeout: 5,
          id: Math.random().toString(36).substr(2, 5),
          variant: 'warning',
        },
      });
    }
    const errorMessage = parseError(message.error);
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: errorMessage,
        id: Math.random().toString(36).substr(2, 5),
        position: 'bottom-right',
        timeout: 5,
        variant: 'warning',
      },
    });
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.SUPPORT_POST_REQUEST, postSupport)]);
}
