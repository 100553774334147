/**
 * @module Actions/User
 * @desc User Actions
 */
import { Login, Register } from 'redux/sagas/auth';

import { createAction } from 'modules/helpers';

import { ActionTypes } from 'literals';

import { UserData } from 'types';

export const login = createAction(
  ActionTypes.USER_LOGIN_REQUEST,
  ({ email, password, confirmRole }: Login) => ({
    email,
    password,
    confirmRole,
  }),
);

export const loginContinue = createAction(
  ActionTypes.USER_LOGIN_CONTINUE,
  ({ email, password, confirmRole }: Login) => ({
    email,
    password,
    confirmRole,
  }),
);

export const recovery = createAction(ActionTypes.FORGOT_PASSWORD_REQUEST, ({ email }) => ({
  email,
}));

export const loginUserWithAccessToken = createAction(
  ActionTypes.SOCIAL_AUTH_REQUEST,
  ({ accessToken }) => ({
    accessToken,
  }),
);

export const authReset = createAction(ActionTypes.USER_RESET, () => ({}));

export const register = createAction(
  ActionTypes.USER_REGISTER_REQUEST,
  ({ email, password, first_name, last_name, username, conditions }: Register) => ({
    email,
    password,
    first_name,
    last_name,
    username,
    conditions,
  }),
);
export const logOut = createAction(ActionTypes.USER_LOGOUT_REQUEST, () => ({}));
