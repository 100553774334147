import { all, fork } from 'redux-saga/effects';

import app from './app';
import auth from './auth';
import billing from './billing';
import campaign from './campaign';
import payment from './payment';
import support from './support';
import user from './user';

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(payment),
    fork(auth),
    fork(app),
    fork(user),
    fork(campaign),
    fork(billing),
    fork(support),
  ]);
}
