import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { CheckoutForm } from 'components/Payment/CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_PAYMENT_KEY);

function Payment({ state, handleChange }) {
  return (
    <div className="PaymentWrapper">
      <Elements stripe={stripePromise}>
        <CheckoutForm handleChange={handleChange} state={state} />
      </Elements>
    </div>
  );
}

export default Payment;
