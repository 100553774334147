/**
 * @module Sagas/GitHub
 * @desc GitHub
 */

import { all, call, put, takeLatest } from 'redux-saga/effects';

import { parseError, request } from 'modules/client';

import { ActionTypes } from 'literals';

import { StoreAction } from 'types';

export interface Payment {
  paymentId?: string;
  priceId?: string;
  amount?: number;
  name?: string;
  email?: string;
  type?: string;
}
export function* createPayment({ payload }: StoreAction) {
  try {
    const response = yield call(request, '/payment/intent', {
      method: 'POST',
      auth: true,
      body: payload.data,
    });

    yield put({
      type: ActionTypes.PAYMENT_CREATE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.PAYMENT_CREATE_FAILURE,
      payload: err,
      meta: { err },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: parseError(err.message),
        position: 'bottom-right',
        timeout: 5,
        id: Math.random().toString(36).substr(2, 5),
        variant: 'warning',
      },
    });
  }
}

export function* createPaymentCostumer({ payload }: StoreAction) {
  try {
    const response = yield call(request, '/payment/create-customer', {
      method: 'POST',
      auth: true,
      body: {
        email: payload.email,
      },
    });

    yield put({
      type: ActionTypes.PAYMENT_COSTUMER_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.PAYMENT_COSTUMER_FAILURE,
      payload: err,
      meta: { err },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: parseError(err.message),
        position: 'bottom-right',
        timeout: 5,
        id: Math.random().toString(36).substr(2, 5),
        variant: 'warning',
      },
    });
  }
}

export function* createPaymentSubscription({ payload }: StoreAction) {
  try {
    const response = yield call(request, '/payment/create-subscription', {
      method: 'POST',
      auth: true,
      body: {
        costumerId: payload.costumerId,
        priceId: payload.priceId,
      },
    });

    yield put({
      type: ActionTypes.PAYMENT_SUBSCRIPTION_CREATE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.PAYMENT_SUBSCRIPTION_CREATE_FAILURE,
      payload: err,
      meta: { err },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: parseError(err.message),
        position: 'bottom-right',
        timeout: 5,
        id: Math.random().toString(36).substr(2, 5),
        variant: 'warning',
      },
    });
  }
}

export function* cancelPaymentSubscription({ payload }: StoreAction) {
  try {
    const response = yield call(request, '/payment/cancel-subscription', {
      method: 'POST',
      auth: true,
      body: {
        subscriptionId: payload.subscriptionId,
      },
    });

    yield put({
      type: ActionTypes.PAYMENT_SUBSCRIPTION_CANCEL_SUCCESS,
      payload: response,
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: 'cancel subscription success',
        position: 'bottom-right',
        timeout: 5,
        id: Math.random().toString(36).substr(2, 5),
        variant: 'success',
      },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.PAYMENT_SUBSCRIPTION_CANCEL_FAILURE,
      payload: err,
      meta: { err },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: parseError(err.message),
        position: 'bottom-right',
        timeout: 5,
        id: Math.random().toString(36).substr(2, 5),
        variant: 'warning',
      },
    });
  }
}

export function* confirmPayment({ payload }: StoreAction) {
  const { logo, ...appValues } = payload.data;
  try {
    const formData = new FormData();
    Object.entries(appValues).forEach(([key, value]) => {
      if (value) {
        if (typeof value === 'object' || Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value as string | File);
        }
      }
    });
    if (logo) {
      formData.append('logo', logo as File);
    }
    const response = yield call(request, '/payment/confirm', {
      method: 'POST',
      auth: true,
      formData: true,
      body: formData,
    });

    yield put({
      type: ActionTypes.PAYMENT_CONFIRM_SUCCESS,
      payload: response,
    });
    yield put({
      type: ActionTypes.GET_USER_REQUEST,
      payload: response,
    });
    yield put({
      type: ActionTypes.GET_CAMPAIGN_REQUEST,
      payload: response,
    });
    yield put({
      type: ActionTypes.IS_PRO_PLAN_APP_REQUEST,
      payload: {
        isProPlanPage: false,
      },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.PAYMENT_CONFIRM_FAILURE,
      payload: err,
      meta: { err },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: parseError(err.message),
        position: 'bottom-right',
        timeout: 5,
        id: Math.random().toString(36).substr(2, 5),
        variant: 'warning',
      },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.PAYMENT_CREATE_REQUEST, createPayment),
    takeLatest(ActionTypes.PAYMENT_CONFIRM_REQUEST, confirmPayment),
    takeLatest(ActionTypes.PAYMENT_COSTUMER_REQUEST, createPaymentCostumer),
    takeLatest(ActionTypes.PAYMENT_SUBSCRIPTION_CREATE_REQUEST, createPaymentSubscription),
    takeLatest(ActionTypes.PAYMENT_SUBSCRIPTION_CANCEL_REQUEST, cancelPaymentSubscription),
  ]);
}
