import styled from 'styled-components';

export const StyledCampaign = styled.div`
  .campaignView {
    display: flex;
    align-items: flex-start;
    padding: 40px;
  }
  .campaignInfo {
    display: flex;
    font-size: 16px;
    margin: 20px 0;
    span {
      &:last-child {
        color: #6a52b0;
        margin-left: 20px;
      }
    }
  }
  .report {
    display: flex;
    flex: 6;
    flex-direction: column;
    .progress {
      width: 100%;
      padding-right: 30px;
      .dates {
        margin-top: 10px;
        margin-bottom: 30px;
      }
    }
  }
  .reportContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    margin-bottom: 30px;
  }

  .additionalInfoList {
    display: flex;
    flex-direction: row;
    .additionalInfo {
      &:last-of-type {
        border: none;
      }
    }
  }
  .campaignLogo {
    display: flex;
    flex-direction: column;
    flex: 4;
    min-width: 400px;
  }

  .progress {
    display: flex;
    flex: 8;
  }
`;
