import styled from 'styled-components';
import { Container } from 'styled-minimal';

export const StyledSupport = styled(Container)`
  align-items: center;
  text-align: center;
  display: flex;

  a {
    color: #fff;
    line-height: 1;
  }

  a {
    text-decoration: underline;
  }
`;
