import styled from 'styled-components';

export const PaymentStyled = styled.div`
  body,
  html {
    background-color: #f6f9fc;
    font-size: 18px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  }
  .invalid-feedback {
    width: 100%;
    margin-top: -10px;
    font-size: 12px;
    color: #e51c23;
  }
  .text-danger {
    input {
      border: 1px solid #e51c23 !important;
    }
  }
  h1 {
    color: #32325d;
    font-weight: 400;
    line-height: 50px;
    font-size: 40px;
    margin: 20px 0;
    padding: 0;
  }
  &.PaymentCampaign {
    margin-left: 50px;
  }

  .PaymentWrapper {
    margin-left: 50px;
  }
  .ErrorMessage {
    font-size: 12px;
    color: red;
  }
  .Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0 5px;
  }

  button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    width: 140px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6a52b0;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  form {
    margin-bottom: 40px;
    padding-bottom: 40px;
  }

  button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #6a52b0;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  .StripeElement {
    width: 330px;
    display: block;
    margin: 20px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    background: white;
  }
  .form-group {
    label {
      font-family: DM Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #626262;
    }
    input {
      width: 330px;
      display: block;
      margin: 5px 0 10px 0;
      padding: 10px 14px;
      border: 1px solid #d3d3d3;
      border-radius: 10px;
      background: white;
      outline: none;
      &:focus {
        box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
      }
    }
  }
  .FormGroup {
    border: none;
    margin: 0;
    padding: 0;
  }
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  &.PaymentSuccess {
    .newCampaign {
      width: 200px;
      background: transparent;
      color: #6a52b0;
      border: #6a52b0 1px solid;

      .buttonContainer {
        .buttonName {
          font-weight: 700;
          text-transform: none;
          font-size: 14px;
          font-style: normal;
        }
      }
    }
    .panelCampaign {
      width: 200px;
      margin-top: 50px;
      margin-right: 30px;
      .buttonContainer {
        .buttonName {
          font-weight: 700;
          text-transform: none;
          font-size: 14px;
          font-style: normal;
        }
      }
    }

    .ResultCampaignPay {
      display: flex;
      align-items: center;
      margin: 7% 0;
      .PayContainer {
        margin: auto;
      }
      .ResultCampaignPayContainer {
        width: 450px;
        display: flex;
        .ResultTitleContainer {
          .Title {
            font-family: DM Sans;
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 39px;
            letter-spacing: 0px;
            text-align: left;
            color: #1c1939;
          }
          .Description {
            margin-top: 20px;
            margin-right: 30px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.22580644488334656px;
            text-align: left;
            color: #4d5157;
          }
        }
      }
    }
  }
`;
