import React from 'react';
import { StyledWSA } from 'pages/WSA/Style';

function WSA() {
  return (
    <StyledWSA fullScreen ySpacing>
      <h1>WSA</h1>
    </StyledWSA>
  );
}

export default WSA;
