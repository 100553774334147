import React from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row } from 'reactstrap';
import { isProPlan, showDialog } from 'redux/actions';
import welcomeSystem from 'images/welcomeSystem.png';
import { WelcomeContainer } from 'pages/MyApps/Style';
import { ButtonDefault, StyledFileUpload } from 'styles';

import { useShallowEqualSelector } from 'modules/hooks';

import { StoreState } from 'types';

function MyApp() {
  const history = useHistory();
  const { user } = useShallowEqualSelector((s: StoreState) => s.user);
  const dispatch = useDispatch();

  const redirectToProPlan = () => {
    dispatch(
      isProPlan({
        isProPlanPage: false,
      }),
    );
    history.push('/pro_plan');
  };

  const handleClickOpenDialog = (type: string) => {
    dispatch(
      showDialog({
        type,
      }),
    );
  };
  return (
    <WelcomeContainer>
      <div className="welcomePbl">
        <h1>Welcome Publisher 🚀</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum nam vestibulum morbi ac,
          faucibus mattis metus, enim.
        </p>
      </div>
      <div className="welcomeUpload">
        <StyledFileUpload>
          <Row form className="uploadContainer">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              <div className="fileUploadStyle">
                <span>
                  <SVG src={`${process.env.PUBLIC_URL}/media/icons/upload.svg`} />
                </span>
              </div>
              <input
                type="file"
                accept=".jpg, .jpeg, .gif, .png"
                name="icon"
                disabled={true}
                // maxFileSize={5242880}
                multiple={false}
              />
            </label>
          </Row>
        </StyledFileUpload>
        <h1>Upload you first app</h1>
        <p>Make the most of your PWA and share it with the world through our platform.</p>
        <ButtonDefault
          color="default"
          type="button"
          data-testid="Login"
          className="becomePro"
          onClick={e => handleClickOpenDialog('app')}
        >
          <div className="buttonContainer">
            <div>Upload Now</div>
          </div>
        </ButtonDefault>
      </div>
      {user && !user.pro_plan && (
        <div className="beUnlimited">
          <img src={welcomeSystem} alt="welcomeSystem" />
          <div className="getUnlimited">
            <div className="getUnlimitedMore">More than 1 app? </div>
            <div className="getUnlimitedGet"> Get Unlimited.</div>
          </div>
          <p>
            Make the most of WSA and upgrade to a pro account with the ability of publishing
            multiple apps.
          </p>
          <ButtonDefault
            color="default"
            type="button"
            data-testid="Login"
            className="becomePro"
            onClick={redirectToProPlan}
          >
            <div className="buttonContainer">
              <div>Become Pro</div>
            </div>
          </ButtonDefault>
        </div>
      )}
    </WelcomeContainer>
  );
}

export default MyApp;
