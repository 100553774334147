import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import { login, register, showDialog } from 'redux/actions';
import { Register } from 'redux/sagas/auth';
import Link from '@material-ui/core/Link';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Facebook from 'images/facebook.png';
import Google from 'images/google.png';
import signInImage from 'images/signInLogo.png';
import { auth } from 'pages/SignIn';
import { MainContainer } from 'pages/SignUp/Style';
import { ButtonDefault, CheckBoxDefault, InputDefault } from 'styles';

import { STATUS } from 'literals';

import Logo from 'components/Logo';

import { AuthState, StoreState } from 'types';

function SignUp() {
  const dispatch = useDispatch();
  const handleClickOpenDialog = (type: string) => {
    dispatch(
      showDialog({
        type,
      }),
    );
  };
  const { status }: AuthState = useSelector((state: StoreState) => state.auth);

  const [state, setState] = useState({
    email: '',
    password: '',
    username: '',
    first_name: '',
    last_name: '',
    conditions: false,
  });
  const [isPassword, setIsPassword] = useState(false);
  const handleChange = (event: { target: HTMLInputElement }) => {
    const { id, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };

  useEffect(() => {
    if (status === STATUS.SUCCESS) {
      dispatch(login(state));
    }
  }, [status]);

  const handleCheckChange = (event: { target: HTMLInputElement }) => {
    const { id, checked } = event.target;
    setState(prevState => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const handleClickRegister = () => {
    dispatch(register(state as Register));
  };

  const showPassword = () => {
    setIsPassword(prevState => !prevState);
  };

  return (
    <MainContainer className="fullHeight">
      <div className="leftSide">
        <Logo type="white" />
        <div className="signUpWrap">
          <img className="welcomeImg" src={signInImage} alt="welcomeImg" />
          <div className="welcomeText">Get the best apps and save your device storage</div>
        </div>
      </div>
      <div className="rightSide">
        <div className="rightSideContainer">
          <h1 className="signTitle">
            Create your
            <p className="bold">free account.</p>
          </h1>
          <AvForm className="signupForm" onValidSubmit={handleClickRegister} model={state}>
            <div className="userName">
              <InputDefault className="firstName">
                <AvField
                  name="firstname"
                  required
                  errorMessage="The First Name is required"
                  type="string"
                  className="form-control"
                  id="first_name"
                  aria-describedby="first_name"
                  placeholder="First Name"
                  value={state.first_name}
                  onChange={handleChange}
                />
              </InputDefault>
              <InputDefault>
                <AvField
                  name="lastname"
                  required
                  errorMessage="The Last Name is required"
                  type="string"
                  className="form-control"
                  id="last_name"
                  aria-describedby="last_name"
                  placeholder="Last Name"
                  value={state.last_name}
                  onChange={handleChange}
                />
              </InputDefault>
            </div>
            <InputDefault>
              <AvField
                name="username"
                required
                errorMessage="The Publisher Name is required"
                className="form-control"
                type="string"
                id="username"
                aria-describedby="username"
                placeholder="Publisher Name"
                value={state.username}
                onChange={handleChange}
              />
            </InputDefault>
            <InputDefault>
              <AvField
                name="email"
                type="email"
                className="form-control"
                id="email"
                aria-describedby="emailHelp"
                value={state.email}
                onChange={handleChange}
                placeholder="Email"
                validate={{
                  required: { value: true, errorMessage: 'The email is required' },
                  maxLength: {
                    value: 50,
                    errorMessage: 'The email must be less than 50 characters',
                  },
                  email: { value: true, errorMessage: 'Please enter a valid email' },
                }}
              />
            </InputDefault>
            <InputDefault>
              <span onClick={showPassword} className="eye" id="togglePassword">
                <SVG src={`${process.env.PUBLIC_URL}/media/icons/show-password.svg`} />
              </span>
              <AvField
                name="password"
                type={isPassword ? 'string' : 'password'}
                required
                errorMessage="The password is required"
                className="form-control"
                id="password"
                placeholder="Password"
                value={state.password}
                onChange={handleChange}
              />
            </InputDefault>
            <CheckBoxDefault className="conditions">
              <label htmlFor="conditions" className="checkbox-button">
                <input
                  type="checkbox"
                  className="checkbox-button__input"
                  id="conditions"
                  name="conditions"
                  defaultChecked={state.conditions}
                  onChange={handleCheckChange}
                />
                <span className="checkbox-button__control" />
                <span className="checkbox-button__label">
                  I’ve read the{' '}
                  <Link onClick={e => handleClickOpenDialog('terms')} className="link">
                    terms and conditions.
                  </Link>
                </span>
              </label>
            </CheckBoxDefault>

            <ButtonDefault
              className="default"
              color="default"
              disabled={status === STATUS.RUNNING || !state.conditions}
              type="submit"
              data-testid="register"
            >
              <div className="buttonContainer">
                <div className="buttonName">Create account</div>
              </div>
            </ButtonDefault>
            <div className="or">
              <div className="leftLine">
                <SVG src={`${process.env.PUBLIC_URL}/media/icons/line1.svg`} />
              </div>
              <div className="text">or</div>
              <div className="rightLine">
                <SVG src={`${process.env.PUBLIC_URL}/media/icons/line2.svg`} />
              </div>
            </div>

            <ButtonDefault
              disabled={status === STATUS.RUNNING}
              color="black"
              onClick={e => auth(e, '/auth/google/console')}
              background="google"
              className="google"
              type="button"
              data-testid="Login"
            >
              <div className="buttonContainer">
                <span className="buttonIcon">
                  <img src={Google} alt="google" />
                </span>
                <div className="buttonName">Create with Google</div>
              </div>
            </ButtonDefault>

            <ButtonDefault
              disabled={status === STATUS.RUNNING}
              color="default"
              onClick={e => auth(e, '/auth/facebook/console')}
              background="facebook"
              className="facebook"
              type="button"
              data-testid="Login"
            >
              <div className="buttonContainer">
                <span className="buttonIcon">
                  <img src={Facebook} alt="facebook" />
                </span>
                <div className="buttonName">Create with Facebook</div>
              </div>
            </ButtonDefault>
          </AvForm>
        </div>
      </div>
    </MainContainer>
  );
}

export default SignUp;
