import styled from 'styled-components';

export const InputDefault = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  .invalid-feedback {
    width: 100%;
    margin-top: 0.2rem;
    font-size: 12px;
    color: #e51c23;
  }
  .is-invalid {
    border: 1px solid #e51c23 !important;
  }
  .text-danger {
    label {
      color: #e51c23;
    }
  }
  input {
    outline: none;
    font-size: 16px;
  }
  .form-group {
    label {
      margin-right: 0;
      margin-left: 4px;
      margin-bottom: 2px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      font-family: DM Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.22580644488334656px;
      text-align: left;
      color: #626262;

      .additional-label-text {
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.22580644488334656px;
        text-align: right;
        color: #8769e0;
      }
    }
  }

  .av-invalid {
    position: relative;
  }
  .eye {
    position: absolute;
    cursor: pointer;
    z-index: 100;
    margin-right: 0;
    right: 10px;
  }
  input {
    display: flex;
    width: 100%;
    height: 45px;
    padding: 10px;
    border: 1px solid #d3d3d3;
    border-radius: 12px;
  }
  &.textAreaField {
    textarea {
      font: 400 16px DM Sans;
      ::placeholder {
        font-family: DM Sans;
        font-size: 16px;
        text-align: left;
        color: #757575;
      }

      height: 105px;
      width: 100%;
      resize: none;
      padding: 10px;
      border: 1px solid #d3d3d3;
      border-radius: 12px;
      outline: none;
      &.text-danger {
        border: 1px solid #e51c23;
      }
    }
  }

  &.selectField {
    width: 100%;
    select {
      font-family: DM Sans;
      font-size: 16px;
      text-align: left;
      color: #757575;
      width: 100%;
      border: 1px solid #d3d3d3;
      height: 45px;
      border-radius: 12px;
      padding: 10px;
      outline: none;
      appearance: none;
      background: url("data:image/svg+xml,
      <svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
      width='451.847px' height='451.847px' viewBox='0 0 451.847 451.847' style='enable-background:new 0 0 451.847 451.847;'
      xml:space='preserve'>
      <path  fill='rgb(106, 82, 176)' d='M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
      c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
      c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z'/></svg>")
        96% / 4% no-repeat rgb(255 255 255);
    }
  }

  &.uploadFilesButton {
    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
`;

export const ChooseColor = styled.div`
  label {
    cursor: pointer;
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    display: flex;
    width: 160px;
    height: 45px;
    align-items: center;
    .colorPick {
      border-radius: 10px;
      width: 35px;
      height: 35px;
      margin-left: 5px;
      margin-right: 10px;
      border: 1px solid #d3d3d3;
    }
    .colorPickerLabel {
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: #626262;
    }
    input {
      visibility: hidden;
      width: 0;
      height: 0;
      margin-top: 40px;
      &:disabled {
        background: #d3d3d3;
        cursor: not-allowed;
      }
    }
  }
`;
