import React, { useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteUser, getUser, logOut, updateUser } from 'redux/actions';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { ButtonDefault, CreateAppPopUp, InputDefault } from 'styles';

import { useShallowEqualSelector } from 'modules/hooks';

import { STATUS } from 'literals';

import Loader from 'components/Loader';

import { StoreState, UserData } from 'types';

function UserForm({ onClose }): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, status } = useShallowEqualSelector((s: StoreState) => s.user);
  const [changePassword, setChangePassword] = useState(false);
  const [state, setState] = useState<UserData>({
    first_name: '',
    last_name: '',
    email: '',
    username: '',
    password: '',
    repeat_password: '',
  });
  const [deleteAccount, setDeleteAccount] = useState<boolean>(false);

  const onChangePassword = () => {
    setChangePassword(prevState => !prevState);
  };

  useEffect(() => {
    if (status === STATUS.SUCCESS && !deleteAccount) {
      setState({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        username: user.username,
      });
    }
    if (status === STATUS.ERROR) {
      history.push('/server-error');
    }
  }, [dispatch, user]);

  const onSubmit = () => {
    dispatch(updateUser(state as UserData, user.id));
    onClose();
  };

  useEffect(() => {
    if (status === STATUS.SUCCESS && deleteAccount) {
      dispatch(logOut());
      localStorage.clear();
      onClose();
      history.push('/login');
    }
  }, [status]);

  const onDelete = event => {
    event.preventDefault();
    setDeleteAccount(true);
    dispatch(deleteUser(user.id));
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { id, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };

  const avForm = useRef<{ reset: any }>();

  return status === STATUS.RUNNING ? (
    <Loader />
  ) : (
    <CreateAppPopUp>
      <div className="popUpTitle">
        <div>My account</div>
      </div>
      <AvForm ref={avForm} className="editAccountContainer" onValidSubmit={onSubmit} model={state}>
        <div>
          <InputDefault>
            <AvField
              name="first_name"
              required
              label="First Name"
              errorMessage="The firstName is required"
              type="text"
              className="form-control"
              id="first_name"
              aria-describedby="app_name"
              placeholder="first Name"
              value={state.first_name}
              onChange={handleChange}
            />
          </InputDefault>
        </div>

        <div>
          <InputDefault>
            <AvField
              name="last_name"
              required
              errorMessage="The last_name is required"
              type="text"
              className="form-control"
              id="last_name"
              label="Last Name"
              aria-describedby="lastName"
              placeholder="lastName"
              value={state.last_name}
              onChange={handleChange}
            />
          </InputDefault>
        </div>

        <div>
          <InputDefault>
            <AvField
              name="email"
              required
              errorMessage="The email is required"
              type="text"
              className="form-control"
              id="email"
              label="Email"
              aria-describedby="email"
              placeholder="email"
              value={state.email}
              onChange={handleChange}
            />
          </InputDefault>
        </div>

        <div>
          <InputDefault>
            <AvField
              name="username"
              required
              errorMessage="The username is required"
              type="text"
              className="form-control"
              id="username"
              label="Publisher Name"
              aria-describedby="username"
              placeholder="username"
              value={state.username}
              onChange={handleChange}
            />
          </InputDefault>
        </div>

        <div className="changePassword">
          <div className="changePasswordText">Password</div>
          <div className="editSvg" onClick={onChangePassword}>
            <SVG src={`${process.env.PUBLIC_URL}/media/icons/editApp.svg`} />
          </div>
        </div>
        <div className="changePasswordBlock">
          {changePassword && (
            <div>
              <InputDefault>
                <AvField
                  name="password"
                  required
                  errorMessage="Your password is required"
                  type="password"
                  className="form-control"
                  id="password"
                  aria-describedby="app_name"
                  placeholder="Your password"
                  value={state.password}
                  onChange={handleChange}
                />
              </InputDefault>
              <InputDefault>
                <AvField
                  name="repeat_password"
                  required
                  errorMessage="Repeat password field is required"
                  type="password"
                  className="form-control"
                  id="repeat_password"
                  aria-describedby="repeat_password"
                  placeholder="Repeat password"
                  value={state.repeat_password}
                  onChange={handleChange}
                />
              </InputDefault>
            </div>
          )}
        </div>

        <div className="bottomButtons">
          <ButtonDefault
            className="deleteAccount"
            color="red"
            background="transparent"
            type="button"
            onClick={onDelete}
          >
            <div className="buttonContainer">
              <div className="buttonName">Delete account</div>
            </div>
          </ButtonDefault>
          <ButtonDefault color="default" type="submit">
            <div className="buttonContainer">
              <div className="buttonName">Save</div>
            </div>
          </ButtonDefault>
        </div>
        <div className="closePopUp" onClick={onClose}>
          <SVG src={`${process.env.PUBLIC_URL}/media/icons/cancel.svg`} />
        </div>
      </AvForm>
    </CreateAppPopUp>
  );
}

export default UserForm;
