import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { usePrevious } from 'react-use';
import { getCampaignById } from 'redux/actions';
import { BorderLinearProgress, getOriginalIMG } from 'pages/Campaign/List';
import { StyledCampaign } from 'pages/Campaign/View/Style';

import { useShallowEqualSelector } from 'modules/hooks';

import Graphic from 'components/Graphic';
import Loader from 'components/Loader';

import { StoreState } from 'types';

function CampaignView(props) {
  const dispatch = useDispatch();
  const { campaign } = useShallowEqualSelector((s: StoreState) => s.campaign);
  const previousCampaign = usePrevious(campaign);
  let progress = 0;
  useEffect(() => {
    if (previousCampaign !== campaign) {
      dispatch(getCampaignById(props.match.params.id));
    }
  }, [dispatch]);

  if (campaign) {
    const newDate: any = new Date();
    const startDate: any = new Date(campaign.start_date);
    const endDate: any = new Date(campaign.finish_date);
    const diff = Math.max(0, endDate - newDate);
    const range = endDate - startDate;
    progress = (100 * diff) / range;
    if (isNaN(progress)) {
      progress = 100;
    }

    if (progress <= 0) {
      progress = 100;
    }

    if (progress > 100) {
      progress = 0;
    }
  }

  return (
    <StyledCampaign>
      {campaign ? (
        <div className="campaignView">
          <div className="campaignLogo">
            <div className="appInfo">
              <div className="appImage">
                {campaign.logo ? (
                  <img src={getOriginalIMG(campaign.logo)} alt={campaign.app.name} />
                ) : (
                  <div
                    style={{
                      background: campaign.color,
                    }}
                    className="campaign-background"
                  />
                )}
              </div>
              {campaign.app && (
                <div className="appTitleContainer">
                  <div className="appTitle">{campaign.app.name}</div>
                  <div className="appCategory">{campaign.app.slug}</div>
                </div>
              )}
            </div>
            <div className="campaignInfo">
              <span>Will be shown in:</span>
              <span>Banners</span>
            </div>
            <div
              className="bannerLookContainer"
              style={{
                background: campaign.cover
                  ? `url(${getOriginalIMG(campaign.logo)})`
                  : campaign.color,
              }}
            >
              {!campaign.cover && (
                <div className="bannerLogo">
                  <img src={getOriginalIMG(campaign.logo)} alt={campaign.app.name} />
                </div>
              )}
              <div className="bannerLookFooter">
                {campaign.app && (
                  <div className="bannerLogoContainer">
                    <div className="appIcon">
                      {campaign.app.icon && (
                        <img
                          width={20}
                          height={20}
                          src={getOriginalIMG(campaign.app.icon)}
                          alt={campaign.app.icon.name}
                        />
                      )}
                    </div>
                    <div className="appInfo">
                      <div className="appName">{campaign.app.name}</div>
                      {campaign.app.categories && (
                        <div className="appCategory">{campaign.app.categories[0].name}</div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="report">
            <div className="progress">
              <BorderLinearProgress
                className="progress-line"
                variant="determinate"
                value={progress || 0}
              />
              <div className="dates">
                <div>
                  Start
                  <span className="dates-start">
                    {campaign.start_date.toString().substr(0, 10).split('-').join('/')}
                  </span>
                </div>
                <div>
                  End
                  <span className="dates-end">
                    {campaign.finish_date.toString().substr(0, 10).split('-').join('/')}
                  </span>
                </div>
              </div>
            </div>
            <div className="reportContent">
              <p>Campaign report</p>
              <div className="additionalInfoList">
                <div className="additionalInfo">
                  <div className="count">{campaign.impressions}</div>
                  <div className="title">Impressions</div>
                </div>
                <div className="additionalInfo">
                  <div className="count">{campaign.app.installs.length}</div>
                  <div className="title">Times added</div>
                </div>
                <div className="additionalInfo">
                  <div className="count count-cents">
                    {campaign.impressions
                      ? (campaign.impressions / campaign.payment.amount).toFixed(2)
                      : campaign.impressions}
                    <div className="title">Cents</div>
                  </div>
                  <div className="title">Cost per impressions</div>
                </div>
                <div className="additionalInfo">
                  <div className="count count-cents">
                    {campaign.app.installs.length
                      ? (campaign.app.installs.length / campaign.payment.amount).toFixed(2)
                      : campaign.app.installs.length}
                    <div className="title">Cents</div>
                  </div>
                  <div className="title">Cost per times added</div>
                </div>
              </div>
            </div>
            <Graphic campaign={campaign} />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </StyledCampaign>
  );
}

export default CampaignView;
