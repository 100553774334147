import React, { useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { Row } from 'reactstrap';
import { deleteApp, replaceApp, showAlert, uploadApp } from 'redux/actions';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { useApiService } from 'hooks/useApiService';
import { useEffectOnMount } from 'hooks/useEffectOnMount';
import { ButtonDefault, CreateAppPopUp, InputDefault, StyledFileUpload } from 'styles';

import { SelectOption } from 'modules/client';
import { useShallowEqualSelector } from 'modules/hooks';

import { STATUS } from 'literals';

import { AppData, StoreState } from 'types';

function CreateApp({ onClose }): JSX.Element {
  const dispatch = useDispatch();
  const { status } = useShallowEqualSelector((s: StoreState) => s.app);
  const [state, setState] = useState<AppData>({
    name: '',
    url: '',
    description: '',
    categories: [],
    icon: undefined,
    status: 'Published',
  });
  const getOriginalIMG = img =>
    img ? img.variations.find(v => v.variation === 'original')!.url : '';
  const [categories, setCategories] = useState<SelectOption[]>([]);
  const [fetchCategories, { isLoading: isFetchingCategories }] = useApiService(
    'category',
    categoryService => () =>
      categoryService.fetchCategoriesSelectOptions().then(rec => setCategories(rec)),
  );
  const { appById } = useShallowEqualSelector((s: StoreState) => s.app);
  const [icon, setPicture] = useState('');

  useEffect(() => {
    if (appById) {
      setState(appById);
      if (appById.icon) {
        const originIcon = getOriginalIMG(appById.icon);
        setPicture(originIcon);
        setState(prevState => ({
          ...prevState,
          icon: undefined,
        }));
      }
    }
  }, [appById]);

  const onChangeAppIcon = e => {
    if (e.target.files[0]) {
      if (e.target.files[0].size > 5242880) {
        dispatch(
          showAlert(`The file must be less than ${5242880 / 1024 / 1024}MB`, {
            variant: 'warning',
            timeout: 10,
          }),
        );
        return;
      }
      setState(prevState => ({
        ...prevState,
        icon: e.target.files[0],
      }));
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPicture(reader.result.toString());
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffectOnMount(() => {
    fetchCategories();
  });

  const onSubmit = (_: React.FormEvent<HTMLFormElement>) => {
    if (!state.icon && !appById.icon) {
      dispatch(
        showAlert('Please upload an app icon', {
          variant: 'warning',
          timeout: 10,
        }),
      );
      return;
    }
    if (!appById || !appById.id) {
      dispatch(uploadApp(state as AppData));
    } else {
      dispatch(replaceApp(state as AppData));
    }
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { id, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleChangeCategory = (event: { target: HTMLInputElement }) => {
    const { value } = event.target;
    if (!value) return;
    setState(prevState => ({
      ...prevState,
      categories: [
        {
          id: categories[value].id,
          label: categories[value].label,
        },
      ],
    }));
  };

  const avForm = useRef<{ reset: any }>();

  const deleteAppId = id => {
    dispatch(deleteApp(id));
  };
  return (
    <CreateAppPopUp>
      <AvForm onValidSubmit={onSubmit} ref={avForm} model={state}>
        <div className="popUpTitle">
          <div>Here you can</div>
          <div>upload your app.</div>
        </div>
        <Row form>
          <InputDefault>
            <AvField
              name="name"
              required
              errorMessage="The App Name is required"
              type="text"
              className="form-control"
              id="name"
              aria-describedby="app_name"
              placeholder="Name"
              value={state.name}
              onChange={handleChange}
            />
          </InputDefault>
        </Row>

        <Row form>
          <InputDefault>
            <AvField
              name="url"
              required
              errorMessage="The App Url is required"
              type="text"
              className="form-control"
              id="url"
              aria-describedby="url"
              placeholder="Url"
              value={state.url}
              onChange={handleChange}
              validate={{
                required: { value: true, errorMessage: 'The url is required' },
                pattern: {
                  value:
                    '/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/',
                  errorMessage: 'The url must be in a valid format',
                },
                maxLength: {
                  value: 1024,
                  errorMessage: 'The url must be less than 1024 characters',
                },
              }}
            />
          </InputDefault>
        </Row>

        <Row form>
          <InputDefault className="textAreaField">
            <AvField
              name="description"
              required
              type="textarea"
              className="form-control"
              id="description"
              aria-describedby="description"
              placeholder="Description"
              value={state.description}
              onChange={handleChange}
              validate={{
                maxLength: {
                  value: 65536,
                  errorMessage: 'The description must be less than 65536 characters',
                },
              }}
            />
          </InputDefault>
        </Row>

        <Row form>
          <InputDefault className="selectField">
            <AvField
              className="form-control"
              type="select"
              isMulti
              onChange={e => handleChangeCategory(e)}
              id="categories"
              name="categories"
              label="Categories"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'This is a required field.',
                },
              }}
            >
              <option value="">Select category</option>
              {categories.map((category, index) => (
                <option key={index} value={index}>
                  {category.label}
                </option>
              ))}
            </AvField>
          </InputDefault>
        </Row>
        <StyledFileUpload>
          <Row form className="uploadContainer">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              <div className="fileUploadStyle">
                <span>
                  <SVG src={`${process.env.PUBLIC_URL}/media/icons/upload.svg`} />
                </span>
              </div>
              <input
                type="file"
                accept=".jpg, .jpeg, .gif, .png"
                name="icon"
                key={icon || ''}
                multiple={false}
                onChange={onChangeAppIcon}
              />
            </label>
            <div className="uploadDescriptionContainer">
              <div className="uploadTitle">App icon</div>
              <div className="uploadDescription">
                <div>Your file should be 100 x 100px</div>
                <div>Max file size - 5 MB</div>
              </div>
            </div>
          </Row>
          {icon && (
            <div className="uploadImageContainer">
              <div className="uploadImage">
                <img width="115px" height="115px" src={icon} alt="imgData" />
                <span className="closeIcon" onClick={e => setPicture(null)}>
                  <SVG src={`${process.env.PUBLIC_URL}/media/icons/cancel.svg`} />
                </span>
              </div>
            </div>
          )}
        </StyledFileUpload>
        {!appById ? (
          <ButtonDefault
            className="default"
            disabled={status === STATUS.RUNNING}
            color="default"
            type="submit"
          >
            <div className="buttonContainer">
              <div className="buttonName">
                {status === STATUS.RUNNING ? STATUS.RUNNING : 'Upload Now'}
              </div>
            </div>
          </ButtonDefault>
        ) : (
          <div className="bottomButtons">
            <ButtonDefault
              className="deleteAccount"
              color="red"
              background="transparent"
              type="button"
              onClick={e => deleteAppId(appById.id)}
            >
              <div className="buttonContainer">
                <div className="buttonName">Remove app</div>
              </div>
            </ButtonDefault>
            <ButtonDefault
              className="default"
              disabled={status === STATUS.RUNNING}
              color="default"
              type="submit"
            >
              <div className="buttonContainer">
                <div className="buttonName">
                  {status === STATUS.RUNNING ? STATUS.RUNNING : 'Save'}
                </div>
              </div>
            </ButtonDefault>
          </div>
        )}
      </AvForm>
      <div className="closePopUp" onClick={onClose}>
        <SVG src={`${process.env.PUBLIC_URL}/media/icons/cancel.svg`} />
      </div>
    </CreateAppPopUp>
  );
}

export default CreateApp;
