import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authReset, login } from 'redux/actions';
import Link from '@material-ui/core/Link';
import { makeStyles, Theme } from '@material-ui/core/styles';
import signInBg from 'images/signInBg.png';
import { MainContainer } from 'pages/SignIn/Style';
import { ButtonDefault, CheckBoxDefault } from 'styles';

import { useShallowEqualSelector } from 'modules/hooks';

import Logo from 'components/Logo';

import { StoreState } from 'types';
import { CarouselUI } from 'components/CarouselUI';

function UpgradeRole() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    conditions: false,
  });

  const handleCheckChange = (event: { target: HTMLInputElement }) => {
    const { id, checked } = event.target;
    setState(prevState => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const { isAuthenticated, user } = useShallowEqualSelector((s: StoreState) => s.auth);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated]);

  const upgradeAndLogin = (event: React.FormEvent<HTMLFormElement>): void => {
    if (!user) {
      history.push('/login');
      dispatch(authReset());
    }
    const { email, password } = user;
    dispatch(
      login({
        email,
        password,
        confirmRole: 'true',
      }),
    );
    event.preventDefault();
  };

  return (
    <MainContainer>
      <div
        className="leftSide signInCarousel"
        style={{
          backgroundImage: `url(${signInBg})`,
        }}
      >
        <Logo type="white" />
        <CarouselUI />
      </div>
      <div className="rightSide publisher">
        <div className="rightSideContainer">
          <h1 className="signTitle">Become a publisher</h1>
          <div className="publisherDescription">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna netus lobortis vel
            fringilla.
          </div>
          <form onSubmit={event => upgradeAndLogin(event)}>
            <CheckBoxDefault className="termsCheck">
              <label className="checkbox-button">
                <input
                  type="checkbox"
                  className="checkbox-button__input"
                  id="conditions"
                  name="conditions"
                  defaultChecked={state.conditions}
                  onChange={handleCheckChange}
                />
                <span className="checkbox-button__control" />
                <span className="checkbox-button__label">
                  I’ve read the <Link className="link">terms and conditions.</Link>
                </span>
              </label>
            </CheckBoxDefault>
            <ButtonDefault
              className="default"
              color="default"
              type="submit"
              data-testid="register"
              disabled={!state.conditions}
            >
              <div className="buttonContainer">
                <div className="buttonName">Start Now</div>
              </div>
            </ButtonDefault>
          </form>
        </div>
      </div>
    </MainContainer>
  );
}

export default UpgradeRole;
