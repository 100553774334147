/**
 * @module Actions/App
 * @desc App Actions
 */
import React from 'react';
import { nanoid } from 'nanoid';

import { createAction } from 'modules/helpers';

import { ActionTypes } from 'literals';

import { AppData, DialogData, ShowAlertOptions } from 'types';

export { go, goBack, push, replace } from 'modules/history';

export const hideAlert = createAction(ActionTypes.HIDE_ALERT, (id: string) => ({ id }));

export const uploadApp = createAction(
  ActionTypes.CREATE_APP_REQUEST,
  ({ name, url, status, description, categories, icon }: AppData) => ({
    name,
    url,
    status,
    description,
    categories,
    icon,
  }),
);

export const deleteApp = createAction(ActionTypes.DELETE_APP_REQUEST, id => id);

export const isProPlan = createAction(ActionTypes.IS_PRO_PLAN_APP_REQUEST, ({ isProPlanPage }) => ({
  isProPlanPage,
}));

export const replaceApp = createAction(
  ActionTypes.UPDATE_APP_REQUEST,
  ({ id, name, url, description, categories, icon }: AppData) => ({
    name,
    url,
    id,
    description,
    categories,
    icon,
  }),
);

export const showAlert = createAction(
  ActionTypes.SHOW_ALERT,
  (message: React.ReactNode, options: ShowAlertOptions) => {
    const timeout = options.variant === 'danger' ? 0 : 5;

    return {
      id: options.id || nanoid(),
      icon: options.icon,
      message,
      position: options.position || 'bottom-right',
      typeColor: '#fffff',
      variant: options.variant || 'dark',
      timeout: typeof options.timeout === 'number' ? options.timeout : timeout,
    };
  },
);

export const showDialog = createAction(ActionTypes.SHOW_DIALOG, ({ type, id }: DialogData) => ({
  type,
  id,
}));

export const hideDialog = createAction(ActionTypes.HIDE_DIALOG, () => ({}));

export const getAppList = createAction(ActionTypes.APP_LIST_REQUEST, () => ({}));
export const getAppById = createAction(ActionTypes.GET_APP_ID, (app: AppData) => ({ app }));
