import React from 'react';
import { DialogContent, Slide } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import CreateApp from 'components/CreateApp';

export const Transition = React.forwardRef<any, any>(function Transition(props, ref): any {
  return <Slide timeout={10} direction="left" ref={ref} {...props} />;
});

export default function AppDialog(props) {
  const { open, onClose } = props;

  return (
    <Dialog
      open={open}
      // @ts-ignore
      TransitionComponent={Transition}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          position: 'fixed',
          right: '0',
          height: '100vh',
          margin: '0',
          maxHeight: '100vh',
          width: '400px',
          padding: '0 30px 0 30px',
          background: '#F5F5F9',
          zIndex: 7,
          transform: 'scale(1)',
          animation: 'fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards',
        },
      }}
    >
      <DialogContent
        style={{
          zIndex: 5,
        }}
      >
        <CreateApp onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
}
