import signUpBg from 'images/signUpBg.png';
import styled from 'styled-components';
import { responsive } from 'styled-minimal';

import { spacer } from 'modules/theme';

export const MainContainer = styled.div`
  &.fullHeight {
    height: 100vh;
  }
  .logoWrapper {
    display: block;
    margin: 20px 40px 0 40px;
  }
  .eye {
    top: 14px;
  }
  .or {
    display: flex;
    text-align: center;
    .leftLine {
      margin-top: 8px;

      border-image-source: linear-gradient(270deg, rgba(231, 231, 231, 0.0001) 0%, #987bef 99.3%);
    }
    .rightLine {
      margin-top: 8px;
      svg {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
      }
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      top: 20px;
      border-image-source: linear-gradient(270deg, rgba(231, 231, 231, 0.0001) 0%, #987bef 99.3%);
    }
    .text {
      z-index: 2;
      color: #1c1939;
      display: inline-block;
      margin-bottom: 10px;
      padding: 10px;
      font-family: DM Sans;
      font-style: Medium;
      font-size: 16px;
    }
  }
  height: 100vh;
  display: flex;
  .termsCheck {
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .left-side {
    padding-left: 50px;
  }
  .welcomeImg {
    margin-top: 80px;
    padding-left: 80px;
    padding-right: 50px;
  }
  .welcomeText {
    margin: 45px auto 0 auto;
    text-align: center;
    max-width: 400px;
    h1 {
      font-family: DM Sans;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 39px;
      letter-spacing: 0;
      text-align: center;
      color: #1c1939;
    }
  }
  .welcomeP {
    color: #4d5157;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .rightSide {
    background: #f5f5f9;
    width: 50%;
  }
  .leftSide {
    background-image: url(${signUpBg});
    width: 50%;
    .appName,
    .welcomeText {
      color: white;
    }

    .welcomeText {
      font-size: 30px;
      margin: 50px 0 0 0;
      width: 100%;
    }
    .signUpWrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .rightSideContainer {
    margin: auto;
    width: 310px;
    .userName {
      display: flex;
      .firstName {
        margin-right: 15px;
      }
    }
  }
  .pricingContainer {
    text-align: center;
    margin: 80px 80px 0 80px;
    h1 {
      font-family: DM Sans;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 39px;
      letter-spacing: 0;
      text-align: center;
      color: #1c1939;
    }
    .cardContainer {
      display: flex;
      justify-content: space-between;
    }
  }

  .signTitle {
    margin-bottom: 40px;
    font-family: DM Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0;
    text-align: left;
    p {
      font-weight: 700;
      margin: 0;
      color: #8769e0;
    }
  }
  .right-side {
    .right-side-container {
      margin: auto;
    }
  }
`;

export const Header = styled.div`
  margin-bottom: ${spacer(3)};
  text-align: center;

  svg {
    height: 10rem;
    width: auto;

    ${
      /* sc-custom '@media-query' */ responsive({
        lg: {
          height: '15rem',
        },
      })
    };
  }
`;

export const Heading = styled.h1`
  color: #fff;
  font-size: 3.5rem;
  line-height: 1.4;
  margin-bottom: ${spacer(3)};
  margin-top: 0;
  text-align: center;

  ${
    /* sc-custom '@media-query' */ responsive({
      lg: {
        fontSize: '4rem',
      },
    })
  };
`;
