import { all, call, put, takeLatest } from 'redux-saga/effects';

import { createRequestQuery, PagedRequestSortOption, parseError, request } from 'modules/client';

import { ActionTypes } from 'literals';

import { AppData, Category } from 'types';

export function* getApps() {
  try {
    const page = 1;
    const limit = 100;
    const sortOptions: PagedRequestSortOption<Category>[] = [{ field: 'name', order: 'asc' }];
    const requestQuery = createRequestQuery({ page, limit, sortOptions });
    const items: Promise<AppData> = yield call(request, `/app/list?${requestQuery}`, {
      auth: true,
    });
    yield put({
      type: ActionTypes.APP_LIST_SUCCESS,
      payload: items,
    });
  } catch (message) {
    if (message === 401) {
      yield put({
        type: ActionTypes.SHOW_ALERT,
        payload: {
          id: Math.random().toString(36).substr(2, 5),
          message: 'Session expired, please sign in again.',
          position: 'bottom-right',
          timeout: 5,
          variant: 'warning',
        },
      });
      yield put({
        type: ActionTypes.USER_LOGOUT_REQUEST,
      });
    }
    const errorMessage = parseError(message.error);
    yield put({
      type: ActionTypes.APP_LIST_FAILURE,
      payload: errorMessage,
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        id: Math.random().toString(36).substr(2, 5),
        message: errorMessage,
        position: 'bottom-right',
        timeout: 5,
        variant: 'warning',
      },
    });
  }
}
export function* uploadApp({ payload }: any) {
  try {
    const { icon, ...appValues }: AppData = payload;
    const formData = new FormData();
    Object.entries(appValues).forEach(([key, value]) => {
      if (value) {
        if (typeof value === 'object' || Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value as string | File);
        }
      }
    });
    if (icon) {
      formData.append('icon', icon as File);
    }

    const items: Promise<AppData> = yield call(request, '/app/frontend', {
      method: 'POST',
      body: formData,
      auth: true,
      formData: true,
    });
    yield put({
      type: ActionTypes.CREATE_APP_SUCCESS,
      payload: items,
    });
    yield put({
      type: ActionTypes.APP_LIST_REQUEST,
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        id: Math.random().toString(36).substr(2, 5),
        message: 'App has been added',
        position: 'bottom-right',
        timeout: 5,
        variant: 'success',
      },
    });
  } catch (error) {
    if (error === 401) {
      yield put({
        type: ActionTypes.SHOW_ALERT,
        payload: {
          id: Math.random().toString(36).substr(2, 5),
          message: 'Session expired, please sign in again.',
          position: 'bottom-right',
          timeout: 5,
          variant: 'warning',
        },
      });
      yield put({
        type: ActionTypes.USER_LOGOUT_REQUEST,
      });
    }
    const errorMessage = parseError(error.message);
    yield put({
      type: ActionTypes.CREATE_APP_FAILURE,
      payload: errorMessage,
      meta: { payload },
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        id: Math.random().toString(36).substr(2, 5),
        message: errorMessage,
        position: 'bottom-right',
        timeout: 5,
        variant: 'warning',
      },
    });
  }
}

export function* updateApp({ payload }: any) {
  try {
    const { icon, id, ...appValues }: AppData = payload;
    const formData = new FormData();
    Object.entries(appValues).forEach(([key, value]) => {
      if (value) {
        if (typeof value === 'object' || Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value as string | File);
        }
      }
    });
    if (icon) {
      formData.append('icon', icon as File);
    }

    const items: Promise<AppData> = yield call(request, `/app/${id}`, {
      method: 'PATCH',
      auth: true,
      body: formData,
      formData: true,
    });
    yield put({
      type: ActionTypes.UPDATE_APP_SUCCESS,
      payload: items,
    });
    yield put({
      type: ActionTypes.APP_LIST_REQUEST,
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        id: Math.random().toString(36).substr(2, 5),
        message: 'update app success',
        position: 'bottom-right',
        timeout: 5,
        variant: 'success',
      },
    });
  } catch (error) {
    const errorMessage = parseError(error.message);
    yield put({
      type: ActionTypes.UPDATE_APP_FAILURE,
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        id: Math.random().toString(36).substr(2, 5),
        message: errorMessage,
        position: 'bottom-right',
        timeout: 5,
        variant: 'warning',
      },
    });
  }
}

export function* deleteApp({ payload }: any) {
  try {
    yield call(request, `/app/${payload}`, {
      method: 'DELETE',
      auth: true,
      formData: true,
    });
    yield put({
      type: ActionTypes.APP_LIST_REQUEST,
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        id: Math.random().toString(36).substr(2, 5),
        message: 'remove app success',
        position: 'bottom-right',
        timeout: 5,
        variant: 'success',
      },
    });
  } catch (error) {
    const errorMessage = parseError(error.message);
    yield put({
      type: ActionTypes.UPDATE_APP_FAILURE,
    });
    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        id: Math.random().toString(36).substr(2, 5),
        message: errorMessage,
        position: 'bottom-right',
        timeout: 5,
        variant: 'warning',
      },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.CREATE_APP_REQUEST, uploadApp),
    takeLatest(ActionTypes.APP_LIST_REQUEST, getApps),
    takeLatest(ActionTypes.UPDATE_APP_REQUEST, updateApp),
    takeLatest(ActionTypes.DELETE_APP_REQUEST, deleteApp),
  ]);
}
