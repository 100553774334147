import styled from 'styled-components';

export const CreateAppPopUp = styled.div`
  position: relative;
  font-size: 14px;
  z-index: 999;
  &.newsletter {
    .popUpTitle {
      margin-bottom: 30px;
    }

    p {
      margin: 0;
    }

    .bottomButtons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        margin-top: 30px;
        max-width: 144px;
      }
    }
  }
  .closePopUp {
    cursor: pointer;
    height: 31px;
    width: 31px;
    position: absolute;
    top: 0;
    right: 0;
    background: #6a52b0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: #ffffff;
      height: 15px;
      width: 15px;
    }
  }
  .popUpTitle {
    font-family: DM Sans;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 20px;
  }
  .billingPro {
    font-size: 14px;
  }
  .billingProAnnual {
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
    span {
      &:last-child {
        color: #f26161;
        cursor: pointer;
        font-weight: 700;
      }
    }
  }
  .popUpText {
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #4d5157;
  }
  .editAccountContainer {
    margin-top: 40px;
  }

  .bottomButtons {
    display: flex;
  }
  .changePassword {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    .changePasswordText {
      font-family: DM Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: -0.3709677457809448px;
      text-align: left;
    }
    .editSvg {
      display: flex;
      align-items: center;
      svg {
        width: 17px;
        height: 17px;
        cursor: pointer;
      }
    }
  }
  .changePasswordBlock {
    margin-top: -35px;
  }
  .deleteAccount {
    border: 1px solid #f26161;
    margin-right: 10px;
  }
`;
