import { keyMirror } from 'modules/helpers';

import { Status } from 'types';

export const ActionTypes = keyMirror({
  PAYMENT_CREATE_REQUEST: undefined,
  PAYMENT_CREATE_SUCCESS: undefined,
  PAYMENT_CREATE_FAILURE: undefined,
  PAYMENT_COSTUMER_REQUEST: undefined,
  PAYMENT_COSTUMER_SUCCESS: undefined,
  PAYMENT_COSTUMER_FAILURE: undefined,
  PAYMENT_SUBSCRIPTION_CREATE_REQUEST: undefined,
  PAYMENT_SUBSCRIPTION_CREATE_SUCCESS: undefined,
  PAYMENT_SUBSCRIPTION_CREATE_FAILURE: undefined,
  PAYMENT_SUBSCRIPTION_CANCEL_REQUEST: undefined,
  PAYMENT_SUBSCRIPTION_CANCEL_SUCCESS: undefined,
  PAYMENT_SUBSCRIPTION_CANCEL_FAILURE: undefined,
  FORGOT_PASSWORD_REQUEST: undefined,
  FORGOT_PASSWORD_SUCCESS: undefined,
  FORGOT_PASSWORD_FAILURE: undefined,
  SOCIAL_AUTH_REQUEST: undefined,
  SOCIAL_AUTH_SUCCESS: undefined,
  SOCIAL_AUTH_FAILURE: undefined,
  BULLING_LIST_REQUEST: undefined,
  BULLING_LIST_SUCCESS: undefined,
  BULLING_LIST_FAILURE: undefined,
  PAYMENT_CONFIRM_REQUEST: undefined,
  PAYMENT_CONFIRM_SUCCESS: undefined,
  PAYMENT_CONFIRM_FAILURE: undefined,
  PAYMENT_FAILURE: undefined,
  SUPPORT_POST_REQUEST: undefined,
  SUPPORT_POST_SUCCESS: undefined,
  SUPPORT_POST_FAILURE: undefined,
  GET_USER_REQUEST: undefined,
  GET_USER_SUCCESS: undefined,
  GET_USER_FAILURE: undefined,
  GET_CAMPAIGN_REQUEST: undefined,
  GET_CAMPAIGN_SUCCESS: undefined,
  GET_CAMPAIGN_FAILURE: undefined,
  GET_CAMPAIGN_ID_REQUEST: undefined,
  GET_CAMPAIGN_ID_SUCCESS: undefined,
  GET_CAMPAIGN_ID_FAILURE: undefined,
  HIDE_ALERT: undefined,
  SHOW_ALERT: undefined,
  HIDE_DIALOG: undefined,
  SHOW_DIALOG: undefined,
  CREATE_APP_REQUEST: undefined,
  DELETE_APP_REQUEST: undefined,
  IS_PRO_PLAN_APP_REQUEST: undefined,
  CREATE_APP_SUCCESS: undefined,
  CREATE_APP_FAILURE: undefined,
  APP_LIST_REQUEST: undefined,
  APP_LIST_SUCCESS: undefined,
  APP_LIST_FAILURE: undefined,
  GET_APP_ID: undefined,
  UPDATE_APP_REQUEST: undefined,
  UPDATE_APP_SUCCESS: undefined,
  UPDATE_APP_FAILURE: undefined,
  USER_LOGIN_CONTINUE: undefined,
  USER_LOGIN_REQUEST: undefined,
  USER_RESET: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,
  USER_REGISTER_REQUEST: undefined,
  USER_REGISTER_SUCCESS: undefined,
  USER_REGISTER_FAILURE: undefined,
  USER_LOGOUT_REQUEST: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,
  USER_UPDATE_REQUEST: undefined,
  USER_UPDATE_SUCCESS: undefined,
  USER_UPDATE_FAILURE: undefined,
  USER_DELETE_REQUEST: undefined,
  USER_DELETE_SUCCESS: undefined,
  USER_DELETE_FAILURE: undefined,
});

export const STATUS: Status = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  CONTINUE: 'continue',
  CONFIRM: 'confirm',
  ERROR: 'error',
};
