/**
 * Configuration
 * @module config
 */

const config = {
  name: 'WeShareApps',
  description:
    'The WeShareApps Publisher Console helps you as a WPA developer to publish and track your apps. We help you get insights through our dashboards and make it easier to distibute your app worldwide.',
};

export default config;
