import jwtDecode from 'jwt-decode';

import { parseError } from 'modules/client';
import { createReducer } from 'modules/helpers';

import { ActionTypes, STATUS } from 'literals';

import { AuthState } from 'types';

export const authState = {
  isAuthenticated: false,
  status: STATUS.IDLE,
  message: '',
  updatedAt: Date.now(),
  accessToken: '',
  user: null,
};

export default {
  auth: createReducer<AuthState>(
    {
      [ActionTypes.USER_RESET]: draft => {
        draft.status = STATUS.IDLE;
        draft.user = null;
      },
      [ActionTypes.SOCIAL_AUTH_REQUEST]: draft => {
        draft.status = STATUS.IDLE;
      },
      [ActionTypes.SOCIAL_AUTH_SUCCESS]: (draft, { payload }) => {
        draft.status = STATUS.SUCCESS;
        draft.accessToken = payload.accessToken;
        draft.isAuthenticated = true;
      },
      [ActionTypes.USER_LOGIN_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.USER_LOGIN_SUCCESS]: (draft, { payload }) => {
        draft.isAuthenticated = true;
        draft.status = STATUS.SUCCESS;
        draft.accessToken = payload.access_token;
        draft.user = jwtDecode(payload.access_token);
      },
      [ActionTypes.USER_LOGIN_CONTINUE]: (draft, { payload }) => {
        draft.isAuthenticated = false;
        draft.status = STATUS.CONTINUE;
        draft.user = payload;
      },
      [ActionTypes.USER_LOGIN_FAILURE]: (draft, { payload }) => {
        draft.isAuthenticated = false;
        draft.message = parseError(payload);
        draft.status = STATUS.ERROR;
        draft.updatedAt = 0;
      },
      [ActionTypes.USER_REGISTER_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.USER_REGISTER_SUCCESS]: draft => {
        draft.status = STATUS.SUCCESS;
      },
      [ActionTypes.USER_REGISTER_FAILURE]: (draft, { payload }) => {
        draft.message = parseError(payload);
        draft.status = STATUS.ERROR;
        draft.updatedAt = 0;
      },
      [ActionTypes.FORGOT_PASSWORD_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.FORGOT_PASSWORD_SUCCESS]: draft => {
        draft.status = STATUS.SUCCESS;
      },
      [ActionTypes.FORGOT_PASSWORD_FAILURE]: (draft, { payload }) => {
        draft.message = parseError(payload);
        draft.status = STATUS.ERROR;
        draft.updatedAt = 0;
      },
      [ActionTypes.USER_LOGOUT_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.USER_LOGOUT_SUCCESS]: draft => {
        draft.isAuthenticated = false;
        draft.user = null;
        draft.accessToken = null;
        draft.status = STATUS.IDLE;
      },
    },
    authState,
  ),
};
