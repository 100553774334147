import React, { useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { login } from 'redux/actions';
import { Login } from 'redux/sagas/auth';
import Link from '@material-ui/core/Link';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Facebook from 'images/facebook.png';
import Google from 'images/google.png';
import { MainContainer } from 'pages/SignIn/Style';
import { ButtonDefault, CheckBoxStyled, InputDefault } from 'styles';
import signInBg from 'images/signInBg.png';

import { STATUS } from 'literals';

import { CarouselUI } from 'components/CarouselUI';

import { AuthState, StoreState } from 'types';

export const auth = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, url: string) => {
  const host = process.env.REACT_APP_API_URL || 'https://console.dev.weshareapps.com/api';
  window.location.href = host + url;
};

function SignIn() {
  const dispatch = useDispatch();
  const history = useHistory();

  // @ts-ignore
  const { status }: AuthState = useSelector<StoreState>(({ auth }) => auth);
  const [state, setState] = useState({
    email: '',
    password: '',
    confirmRole: 'false',
    remember: false,
  });

  const [isPassword, setIsPassword] = useState(false);

  const handleChange = (event: { target: HTMLInputElement }) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleChecked = (event: { target: HTMLInputElement }) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClickLogin = () => {
    dispatch(login(state as Login));
  };

  const showPassword = () => {
    setIsPassword(prevState => !prevState);
  };

  useEffect(() => {
    if (status === STATUS.CONTINUE) {
      history.push('/upgrade-role');
    }
  }, [status]);
  const avForm = useRef<{ reset: any }>();

  return (
    <MainContainer className="fullHeight">
      <div
        className="leftSide signInCarousel"
        style={{
          backgroundImage: `url(${signInBg})`,
          backgroundRepeat: 'round',
        }}
      >
        <CarouselUI />
      </div>
      <div className="rightSide">
        <div className="rightSideContainer">
          <h1 className="signTitle">
            Hi! 👋
            <p className="bold">Welcome back.</p>
          </h1>
          <AvForm ref={avForm} onValidSubmit={handleClickLogin} model={state}>
            <InputDefault>
              <AvField
                name="email"
                id="email"
                aria-describedby="emailHelp"
                value={state.email}
                onChange={handleChange}
                type="email"
                placeholder="Email"
                validate={{
                  required: { value: true, errorMessage: 'The email is required' },
                  maxLength: {
                    value: 50,
                    errorMessage: 'The email must be less than 50 characters',
                  },
                  email: { value: true, errorMessage: 'Please enter a valid email' },
                }}
              />
            </InputDefault>
            <div className="forgotPassContainer">
              <InputDefault className="forgotPass">
                <span onClick={showPassword} className="eye" id="togglePassword">
                  <SVG src={`${process.env.PUBLIC_URL}/media/icons/show-password.svg`} />
                </span>
                <AvField
                  name="password"
                  type={isPassword ? 'string' : 'password'}
                  required
                  errorMessage="The password is required"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  value={state.password}
                  onChange={handleChange}
                />
              </InputDefault>
              <div className="forgotPassword">
                <CheckBoxStyled>
                  <div className="checkbox">
                    <input
                      className="custom-checkbox"
                      type="checkbox"
                      id="remember"
                      name="remember"
                      checked={state.remember}
                      onChange={handleChecked}
                    />
                    <label htmlFor="remember">Remember me</label>
                  </div>
                </CheckBoxStyled>
                <div className="forgotLink">
                  <Link href="/password-recovery">Forgot password?</Link>
                </div>
              </div>
            </div>

            <ButtonDefault
              className="default"
              color="default"
              disabled={status === STATUS.RUNNING}
              type="submit"
              data-testid="Login"
            >
              <div className="buttonContainer">
                <div className="buttonName">Sign in</div>
              </div>
            </ButtonDefault>

            <div className="or">
              <div className="leftLine">
                <SVG src={`${process.env.PUBLIC_URL}/media/icons/line1.svg`} />
              </div>
              <div className="text">or</div>
              <div className="rightLine">
                <SVG src={`${process.env.PUBLIC_URL}/media/icons/line2.svg`} />
              </div>
            </div>

            <ButtonDefault
              disabled={status === STATUS.RUNNING}
              color="black"
              background="google"
              onClick={event => auth(event, '/auth/google/console')}
              type="button"
              className="google"
              data-testid="Login"
            >
              <div className="buttonContainer">
                <span className="buttonIcon">
                  <img src={Google} alt="google" />
                </span>
                <div className="buttonName">Sign in with Google</div>
              </div>
            </ButtonDefault>

            <ButtonDefault
              disabled={status === STATUS.RUNNING}
              color="default"
              background="facebook"
              className="facebook"
              onClick={e => auth(e, '/auth/facebook/console')}
              type="button"
              data-testid="Login"
            >
              <div className="buttonContainer">
                <span className="buttonIcon">
                  <img src={Facebook} alt="facebook" />
                </span>
                <div className="buttonName">Sign in with Facebook</div>
              </div>
            </ButtonDefault>
            <div className="signUp">
              Don't have an account?
              <Link href="/sign-up"> Sign Up</Link>
            </div>
          </AvForm>
        </div>
      </div>
    </MainContainer>
  );
}

export default SignIn;
