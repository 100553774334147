import { parseError } from 'modules/client';
import { createReducer } from 'modules/helpers';

import { ActionTypes, STATUS } from 'literals';

import { PaymentState } from 'types';

export const paymentState: PaymentState = {
  client_secret: null,
  status: STATUS.IDLE,
  message: '',
  costumerId: null,
  subscriptionId: null,
  type: null,
};

export default {
  payment: createReducer<PaymentState>(
    {
      [ActionTypes.PAYMENT_CREATE_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.PAYMENT_CREATE_SUCCESS]: (draft, { payload }) => {
        draft.status = STATUS.CONTINUE;
        draft.client_secret = payload.client_secret;
      },
      [ActionTypes.PAYMENT_CREATE_FAILURE]: (draft, { payload }) => {
        draft.message = parseError(payload);
        draft.status = STATUS.ERROR;
      },
      [ActionTypes.PAYMENT_COSTUMER_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.PAYMENT_COSTUMER_SUCCESS]: (draft, { payload }) => {
        draft.status = STATUS.SUCCESS;
        draft.costumerId = payload.id;
      },
      [ActionTypes.PAYMENT_COSTUMER_FAILURE]: (draft, { payload }) => {
        draft.status = STATUS.ERROR;
      },
      [ActionTypes.PAYMENT_SUBSCRIPTION_CREATE_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.PAYMENT_SUBSCRIPTION_CREATE_SUCCESS]: (draft, { payload }) => {
        draft.status = STATUS.CONTINUE;
        draft.client_secret = payload.latest_invoice.payment_intent.client_secret;
        draft.subscriptionId = payload.id;
      },
      [ActionTypes.PAYMENT_SUBSCRIPTION_CREATE_FAILURE]: (draft, { payload }) => {
        draft.status = STATUS.ERROR;
      },
      [ActionTypes.PAYMENT_SUBSCRIPTION_CANCEL_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.PAYMENT_SUBSCRIPTION_CANCEL_SUCCESS]: (draft, { payload }) => {
        draft.status = STATUS.SUCCESS;
        draft.costumerId = payload.id;
      },
      [ActionTypes.PAYMENT_SUBSCRIPTION_CANCEL_FAILURE]: (draft, { payload }) => {
        draft.status = STATUS.ERROR;
      },
      [ActionTypes.PAYMENT_CONFIRM_REQUEST]: (draft, { payload }) => {
        draft.status = STATUS.RUNNING;
        draft.type = payload.data.type;
      },
      [ActionTypes.PAYMENT_CONFIRM_SUCCESS]: draft => {
        draft.status = STATUS.CONFIRM;
      },
      [ActionTypes.PAYMENT_CONFIRM_FAILURE]: (draft, { payload }) => {
        draft.message = parseError(payload.message);
        draft.status = STATUS.ERROR;
      },
      [ActionTypes.PAYMENT_FAILURE]: (draft, { payload }) => {
        draft.message = payload.data.message;
        draft.status = STATUS.ERROR;
      },
    },
    paymentState,
  ),
};
