import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { getAppList } from 'redux/actions';
import MyAppsList from 'pages/MyApps/List';
import MyApp from 'pages/MyApps/View';

import { useShallowEqualSelector } from 'modules/hooks';

import { STATUS } from 'literals';

import Loader from 'components/Loader';

import { StoreState } from 'types';

function MyAppPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { appList, status } = useShallowEqualSelector((s: StoreState) => s.app);
  const previousAppList = usePrevious(appList);

  useEffect(() => {
    if (appList || !appList.length || previousAppList.length !== appList.length) {
      dispatch(getAppList());
    }

    if (status === STATUS.ERROR) {
      history.push('/server-error');
    }
  }, [dispatch, appList.length]);
  return (
    <div>
      <div>
        {appList && appList.length ? (
          <MyAppsList appList={appList} />
        ) : (
          <div>{status === STATUS.RUNNING ? <Loader /> : <MyApp />}</div>
        )}
      </div>
    </div>
  );
}

export default MyAppPage;
