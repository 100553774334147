import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authReset, recovery } from 'redux/actions';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import signInBg from 'images/signInBg.png';
import { MainContainer } from 'pages/SignIn/Style';
import { ButtonDefault, InputDefault } from 'styles';

import { useShallowEqualSelector } from 'modules/hooks';

import { STATUS } from 'literals';

import { CarouselUI } from 'components/CarouselUI';
import Logo from 'components/Logo';

function PasswordRecovery() {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: '',
  });

  const forgotPassword = (event: React.FormEvent<HTMLFormElement>): void => {
    dispatch(
      recovery({
        email: state.email,
      }),
    );
    event.preventDefault();
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { id, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <MainContainer>
      <div
        className="leftSide signInCarousel"
        style={{
          backgroundImage: `url(${signInBg})`,
        }}
      >
        <CarouselUI />
      </div>
      <div className="rightSide publisherSide">
        <div className="rightSideContainer passwordRecovery">
          <h4 className="signTitle">Forgot your password</h4>
          <p>Please enter your email below</p>
          <AvForm onSubmit={event => forgotPassword(event)} model={state}>
            <InputDefault>
              <AvField
                name="email"
                id="email"
                aria-describedby="emailHelp"
                value={state.email}
                onChange={handleChange}
                type="email"
                placeholder="Email"
                required
              />
            </InputDefault>
            <ButtonDefault
              disabled={status === STATUS.RUNNING}
              color="default"
              type="submit"
              data-testid="register"
            >
              <div className="buttonContainer">
                <div className="buttonName">Send</div>
              </div>
            </ButtonDefault>
          </AvForm>
        </div>
      </div>
    </MainContainer>
  );
}

export default PasswordRecovery;
