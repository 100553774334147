import React from 'react';
import { StyledSupport } from 'pages/Support/Style';

function Support() {
  return (
    <StyledSupport>
      <h1>Support</h1>
    </StyledSupport>
  );
}

export default Support;
