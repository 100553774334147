import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { sendSupport } from 'redux/actions/support';
import { DialogContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';

export const Policy = styled.div`
    padding: 10px 12px 0 25px;
  p {
    font-size: 14px;
    line-height: 24px;
    display: block;
    margin: 0;
  }

  .popUpTitle {
  margin-bottom: 30px;
    font-size: 23px;
  }
  .closePopUp {
    cursor: pointer;
    height: 31px;
    width: 31px;
    background: #6a52b0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: #ffffff;
      height: 12px;
      width: 12px;
    }
  }
  .dialogTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export default function PrivacyPolicy(props) {
  const { open, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          position: 'fixed',
          height: '100%',
          margin: '0',
          maxHeight: '90vh',
          width: '600px',
          padding: '0',
          background: '#F5F5F9',
        },
      }}
    >
      <DialogContent>
        <Policy>
          <div className="dialogTop">
            <div className="popUpTitle">Privacy Policy</div>
            <div className="closePopUp" onClick={onClose}>
              <SVG src={`${process.env.PUBLIC_URL}/media/icons/cancel.svg`} />
            </div>
          </div>
          <p className="popUpText">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus sed sem erat lorem mollis
            aliquet. Tincidunt et sapien blandit phasellus est nibh sed. Tincidunt urna massa sed
            est lacus. Fermentum diam tincidunt leo sodales sit massa, volutpat orci, faucibus.
            Fames enim nullam urna cras eu commodo non. Congue enim suspendisse volutpat sem id.
            Enim eu, cras purus a. Mattis pulvinar eget etiam venenatis, nullam lorem lacus,
            suspendisse. Nisl, ipsum tempus auctor integer ullamcorper in luctus. Dignissim placerat
            porta mi, enim pretium. Sollicitudin etiam semper magna neque facilisi. Egestas
            consequat commodo ut massa justo et. Fermentum feugiat ut mauris tortor. Maecenas diam
            velit, ac in aenean risus egestas. Neque, egestas tincidunt aliquet consectetur integer
            nulla quam sit aenean. Facilisis et, velit sodales eu tellus id. Erat consequat donec
            convallis ornare duis. Amet, fermentum velit sed enim facilisi. Aliquam condimentum
            lacinia magna scelerisque orci.
            <p>
              Lorem pulvinar pulvinar morbi porta volutpat egestas egestas maecenas eget. Et fames
              ligula vivamus lobortis
            </p>
            <p>
              lorem dignissim proin. Facilisi risus, odio sit amet urna. Enim suspendisse ornare nec
              blandit scelerisque sit massa. Tortor, aliquet integer dolor in eget at bibendum.
              Risus duis sit in in eget nisi nam hac turpis. Praesent natoque id dapibus blandit
              ipsum diam accumsan tristique commodo. Massa sed lacus, augue lectus leo. Elementum et
              ipsum blandit urna tortor tincidunt nec lacus tristique. Sed arcu, mauris turpis
              elementum facilisi libero vitae at elit. Amet elementum facilisi cursus diam arcu,
              vitae sed. Dui, turpis nec condimentum arcu. Nisl pellentesque in arcu quam auctor
              dictum suspendisse suspendisse pulvinar. Volutpat ultricies dignissim risus eu id.
              Cras enim arcu cursus facilisis nisl. Habitant vitae lobortis lectus adipiscing congue
              gravida diam scelerisque eu. Iaculis netus nisi enim, ultricies varius. Sagittis fusce
              condimentum vitae proin blandit. Eros id et nam gravida eget ac sed felis. Ullamcorper
              tortor orci mauris tincidunt magna quam egestas blandit.
            </p>
          </p>
        </Policy>
      </DialogContent>
    </Dialog>
  );
}
