import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { useShallowEqualSelector } from 'modules/hooks';

import { StyledBillingTable } from 'components/Billing/Style';

import { StoreState } from 'types';

export default function BillingTable({ columns, rows, handleChangePage, handleChangeRowsPerPage }) {
  const { total, offset, count } = useShallowEqualSelector((s: StoreState) => s.billing);

  return (
    <StyledBillingTable>
      <TableContainer>
        <Table className="table" stickyHeader aria-label="sticky table">
          <TableHead className="table-head">
            <TableRow>
              {columns.map(column => (
                <TableCell
                  className="table-row"
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, background: column.background }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {columns.map(column => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      <div className={column.id}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* @ts-ignore*/}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={count}
        page={offset}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </StyledBillingTable>
  );
}
