import styled from "styled-components";
import {spacer} from "modules/theme";
import {responsive} from "styled-minimal";

export const FooterContainer = styled.div`
  margin: 0 30px;
  padding: 20px 0;
  border-top: 1px solid #DFE1E5;
  display: flex;
  justify-content: space-between;
  .allRights {
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    color: #606263;
  }
  .footerLinks {
    cursor: pointer;
    &.termsCond {
      margin-right: 20px;
    }
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    color: #6A52B0;
  }
`
