import { useCallback, useEffect, useRef, useState } from 'react';
import { ApiServices, ApiServicesKeys, ApiServicesType } from 'services';

import { useEffectOnUnmount } from './useEffectOnUnmount';

type RequestFunction<U> = (...params: U[]) => Promise<any>;

type UseApiServiceReturnType<U> = [(...params: U[]) => void, { isLoading: boolean }];

// const log = (debugIdentifier: string, ...params: any[]) => {
//   debugIdentifier && console.log(`[${debugIdentifier}]`, ...params);
// };

// const logInfo = (debugIdentifier: string, ...params: any[]) => {
//   debugIdentifier && console.info(`[${debugIdentifier}]`, ...params);
// };

type RequestState = {
  isLoading: boolean;
  apiServiceParams?: any[];
};

// TODO: when this hook is 100% reliable, we'll remove the logging helper functions
export function useApiService<K extends ApiServicesKeys, U = any>(
  name: K,
  requestFn: (service: ApiServicesType[K]) => RequestFunction<U>,
  debugIdentifier: string = '',
): UseApiServiceReturnType<U> {
  const service = ApiServices[name];

  const [requestState, setRequestState] = useState<RequestState>({
    isLoading: false,
    apiServiceParams: undefined,
  });
  const didCancel = useRef(false);
  const reqHandler = useCallback((...params: U[]) => {
    setRequestState({ isLoading: true, apiServiceParams: params });
  }, []);

  useEffect(() => {
    const { isLoading, apiServiceParams } = requestState;

    if (!isLoading) {
      return;
    }

    const onFinally = () => {
      if (!didCancel.current) {
        setRequestState({ isLoading: false });
      }
    };

    apiServiceParams
      ? requestFn(service)(...apiServiceParams)
          .catch(err => undefined)
          .finally(onFinally)
      : requestFn(service)()
          .catch(err => undefined)
          .finally(onFinally);
  }, [requestState]);

  useEffectOnUnmount(() => {
    didCancel.current = true;
  });

  return [reqHandler, { isLoading: requestState.isLoading }];
}
