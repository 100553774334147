import React from 'react';
import { getOriginalIMG } from 'pages/Campaign/List';

import Payment from 'components/Payment/payment';

function CampaignPay({ state, app, handleChange }) {
  return (
    <div className="reviewWrapper">
      <div className="stepsInfoContainer">
        <h1 className="title">03. Review and Pay</h1>
        <div className="appIconContainer">
          {app.icon && (
            <img width={20} height={20} src={getOriginalIMG(app.icon)} alt={app.icon.name} />
          )}
          <span>{app.label}</span>
        </div>
        <div className="willShow">Will be shown in:</div>
        <div className="willShowIn">
          {state.banner ? <div>Banners - $80/per day</div> : null}
          {state.search_result ? <div>Search results - $7/per day</div> : null}
          {state.news_letter ? <div>Newsletter campaign - $9/per day</div> : null}
          <div>Start on {state.start_date}</div>
          <div>End on {state.finish_date}</div>
          <div className="total">
            <div>TOTAL</div>
            <div>{state.amount} USD</div>
          </div>
        </div>
      </div>
      <div className="campaignPayment">
        <Payment handleChange={handleChange} state={state} />
      </div>
    </div>
  );
}

export default CampaignPay;
