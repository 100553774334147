import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { cancelSubscription, getBillingList } from 'redux/actions';
import { DialogContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { CreateAppPopUp } from 'styles';

import { useShallowEqualSelector } from 'modules/hooks';

import { STATUS } from 'literals';

import { Transition } from 'components/AppDialog';
import BillingTable from 'components/Billing/Table';

import { StoreState } from 'types';

export default function BillingDialog(props) {
  const { open, onClose } = props;
  const [filter, setFilter] = useState({
    offset: 0,
    limit: 10,
  });
  const dispatch = useDispatch();
  const { billings, status, pro_plan } = useShallowEqualSelector((s: StoreState) => s.billing);

  useEffect(() => {
    if (open) {
      dispatch(getBillingList(filter));
    }
  }, [open, filter]);

  const columns = [
    { id: 'type', label: 'REQUESTED', minWidth: 80 },
    { id: 'amount', label: 'AMOUNT', minWidth: 80 },
    { id: 'status', label: 'STATUS', minWidth: 80 },
  ];

  const handleChangePage = (event, offset) => {
    setFilter({
      ...filter,
      offset,
    });
  };

  const cancel = subscriptionId => {
    dispatch(cancelSubscription(subscriptionId));
    onClose();
  };

  const handleChangeRowsPerPage = event => {
    setFilter({
      offset: 0,
      limit: +event.target.value,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      // @ts-ignore
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          position: 'fixed',
          right: '0',
          height: '100vh',
          margin: '0',
          maxHeight: '100vh',
          width: '400px',
          padding: '0',
          background: '#F5F5F9',
        },
      }}
    >
      <DialogContent>
        <CreateAppPopUp>
          <div className="popUpTitle">
            <div>Billing</div>
          </div>
          {pro_plan && (
            <div className="billingPro">
              <div className="proNextPayment">Your next payment is on {pro_plan.nextPayment}</div>
              <div className="billingProAnnual">
                <span>PRO plan $499/annual</span>
                {pro_plan && pro_plan.subscriptionId && (
                  <span onClick={event => cancel(pro_plan.subscriptionId)}>
                    Cancel subscription
                  </span>
                )}
              </div>
            </div>
          )}

          <div>
            {status === STATUS.SUCCESS && (
              <BillingTable
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                columns={columns}
                rows={billings}
              />
            )}
          </div>
          <div className="closePopUp" onClick={onClose}>
            <SVG src={`${process.env.PUBLIC_URL}/media/icons/cancel.svg`} />
          </div>
        </CreateAppPopUp>
      </DialogContent>
    </Dialog>
  );
}
