import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { getAppById, isProPlan, showDialog } from 'redux/actions';
import monitor from 'images/monitor.png';
import { getOriginalIMG } from 'pages/Campaign/List';
import { MyAppsContainerList } from 'pages/MyApps/Style';
import { ButtonDefault, Card, CheckBoxStyled } from 'styles';

import { useShallowEqualSelector } from 'modules/hooks';

import { AppData, StoreState } from 'types';

function MyAppsList({ appList }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [times, setTimes] = useState(0);
  const { user } = useShallowEqualSelector((s: StoreState) => s.user);

  const handleClickOpenDialog = (type: string) => {
    dispatch(
      showDialog({
        type,
      }),
    );
  };

  useEffect(() => {
    if (appList.length) {
      const countTimes = appList.reduce((app, next) => (app += next.installs.length), 0);
      setTimes(countTimes < 10 ? `0${countTimes}` : countTimes);
    }
  }, [appList]);
  const editApp = (app: AppData) => {
    dispatch(getAppById(app));
  };
  const redirectToCreateCampaign = () => {
    history.push('/campaign-create');
  };

  const linkToProPlan = () => {
    dispatch(
      isProPlan({
        isProPlanPage: false,
      }),
    );
    history.push('/pro_plan');
  };
  return (
    <MyAppsContainerList>
      <div className="myAppsListContainer">
        <div className="myAppsTitleContainer">
          <div className="myAppsTitle">MyApps</div>
          <div className="userAppsInfo">
            <div className="container">
              <div className="info">
                <div className="count">
                  {appList.length < 10 ? `0${appList.length}` : appList.length}
                </div>
                <div className="title">apps publish</div>
              </div>
              <div className="icon">
                <SVG src={`${process.env.PUBLIC_URL}/media/icons/graphic.svg`} />
              </div>
            </div>
            <div className="container">
              <div className="info">
                <div className="count">{times}</div>
                <div className="title">times added</div>
              </div>
              <div className="icon">
                <SVG src={`${process.env.PUBLIC_URL}/media/icons/graphic.svg`} />
              </div>
            </div>
          </div>
        </div>
        <div className="contentContainer">
          <div className="content">
            {user && user.pro_plan && (
              <div className="uploadNewContainer">
                <div className="uploadNew" onClick={e => handleClickOpenDialog('app')}>
                  <SVG
                    className="headerSvg"
                    src={`${process.env.PUBLIC_URL}/media/icons/upload.svg`}
                  />
                  <div>Upload new app</div>
                </div>
              </div>
            )}
            {appList.map(app => (
              <div key={app.name} className="appsList">
                <div className="appContainer">
                  <div
                    className="appInfo"
                    style={{
                      maxWidth: 'fit-content',
                    }}
                  >
                    <div className="appImage">
                      <img src={getOriginalIMG(app.icon)} alt="facebook" />
                    </div>
                    <div className="appTitleContainer">
                      <div className="appTitle">{app.name}</div>
                      <div className="appCategory">{app.slug}</div>
                    </div>
                  </div>
                  <div className="appButtonsContainer">
                    <div className="additionalInfo">
                      <div className="count">{app.installs.length}</div>
                      <div className="title">times added</div>
                    </div>
                    <div className="buttons">
                      <button
                        type="button"
                        disabled={!!app.campaign}
                        data-tip
                        onClick={redirectToCreateCampaign}
                        data-for="campaign"
                        className="reactTooltip"
                      >
                        <SVG src={`${process.env.PUBLIC_URL}/media/icons/campaign.svg`} />
                      </button>
                      <ReactTooltip
                        className="tooltip"
                        id="campaign"
                        place="top"
                        textColor="#6A52B0"
                        type="light"
                      >
                        Create Campaign
                      </ReactTooltip>
                      <button type="button" data-tip data-for="editApp" className="reactTooltip">
                        <SVG
                          onClick={e => editApp(app)}
                          src={`${process.env.PUBLIC_URL}/media/icons/editApp.svg`}
                        />
                      </button>
                      <ReactTooltip type="light" className="tooltip" id="editApp" place="top">
                        Edit App
                      </ReactTooltip>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {user && !user.pro_plan && (
              <div className="getUnlimitedContainer">
                <div className="unlimitedBlock">
                  <div className="unlimitedTitle">
                    More than 1 app? <span>Get Unlimited.</span>
                  </div>
                  <div className="unlimitedDescription">
                    Make the most of WSA and upgrade to a pro account with the ability of publishing
                    multiple apps.
                  </div>
                  <ButtonDefault
                    className="becomePro"
                    color="default"
                    type="button"
                    onClick={linkToProPlan}
                  >
                    <div className="buttonContainer">
                      <div className="buttonName">Become Pro</div>
                    </div>
                  </ButtonDefault>
                </div>
                <Card>
                  <div className="pricingItemTitle withBg">
                    <div className="title">PRO</div>
                    <div className="pricingPlan">$499/ Anual</div>
                  </div>
                  <div className="pricingItemContent">
                    <div className="pricingItemContainer">
                      <div className="pricingColumn">Apps</div>
                      <div className="pricingColumn unlimited">Unlimited</div>
                    </div>
                    <div className="pricingItemContainer">
                      <div className="pricingColumn">Statistics (app usage)</div>
                      <div className="pricingColumn">
                        <CheckBoxStyled>
                          <div className="checkbox">
                            <input
                              className="custom-checkbox"
                              type="checkbox"
                              value={null}
                              checked={true}
                            />
                            <label htmlFor="banner" />
                          </div>
                        </CheckBoxStyled>
                      </div>
                    </div>
                    <div className="pricingItemContainer">
                      <div className="pricingColumn">App promotions</div>
                      <div className="pricingColumn">
                        <CheckBoxStyled>
                          <div className="checkbox">
                            <input
                              className="custom-checkbox"
                              type="checkbox"
                              value={null}
                              checked={true}
                            />
                            <label htmlFor="banner" />
                          </div>
                        </CheckBoxStyled>
                      </div>
                    </div>
                    <div className="pricingItemContainer">
                      <div className="pricingColumn">Weekly results</div>
                      <div className="pricingColumn">
                        <CheckBoxStyled>
                          <div className="checkbox">
                            <input
                              className="custom-checkbox"
                              type="checkbox"
                              value={null}
                              checked={true}
                            />
                            <label htmlFor="banner" />
                          </div>
                        </CheckBoxStyled>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="startCampaignWrapper">
        <div className="startCampaign">
          <img width="144px" height="162px" src={monitor} alt="monitor" />
          <div className="startCampaignTitle">Increase your App Audience</div>
          <div className="startCampaignDescription">
            Create a campaign and start increasing your audience. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit.
          </div>
          {user && user.pro_plan ? (
            <ButtonDefault color="default" type="button" onClick={redirectToCreateCampaign}>
              <div className="buttonContainer">
                <div className="buttonName">Start a Campaign</div>
              </div>
            </ButtonDefault>
          ) : (
            <ButtonDefault color="default" type="button" onClick={linkToProPlan}>
              <div className="buttonContainer">
                <div className="buttonName">Become Pro</div>
              </div>
            </ButtonDefault>
          )}
        </div>
      </div>
    </MyAppsContainerList>
  );
}

export default MyAppsList;
