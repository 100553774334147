import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { isProPlan } from 'redux/actions';
import Pay from 'images/campaign-pay.png';
import { ButtonDefault } from 'styles';
import { PaymentStyled } from 'styles/payment';
import welcomeSystem from 'images/welcomeSystem.png';

import { useShallowEqualSelector } from 'modules/hooks';

import { StoreState } from 'types';

export function PaymentSuccess() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { type } = useShallowEqualSelector((s: StoreState) => s.payment);
  const redirectToCreateCampaign = () => {
    history.push('/campaign-create');
  };
  const redirectToCampaigns = () => {
    history.push('/campaign');
  };
  const redirectToApps = () => {
    history.push('/');
  };
  useEffect(() => {
    isProPlan({
      isProPlanPage: false,
    });
  }, [dispatch]);
  return (
    <PaymentStyled className="PaymentSuccess">
      {type === 'campaign' && (
        <div className="ResultCampaignPay">
          <div className="PayContainer">
            <div className="ResultCampaignPayContainer">
              <div className="ResultTitleContainer">
                <div className="Title">
                  <div>Awesome 🚀</div>
                  <div>Thank You!</div>
                </div>
                <div className="Description">
                  Your campaign is now set to start running on You should soon be able to see an
                  increase in your apps performance.
                </div>
              </div>
              <div className="Image">
                <img className="PayImg" src={Pay} alt="PayImg" />
              </div>
            </div>

            <ButtonDefault
              className="panelCampaign"
              color="default"
              background="transparent"
              onClick={redirectToCampaigns}
            >
              <div className="buttonContainer">
                <div className="buttonName">Go to campaigns panel</div>
              </div>
            </ButtonDefault>

            <ButtonDefault
              className="newCampaign"
              color="red"
              background="transparent"
              type="button"
              onClick={redirectToCreateCampaign}
            >
              <div className="buttonContainer">
                <div className="buttonName">Create a new campaign</div>
              </div>
            </ButtonDefault>
          </div>
        </div>
      )}
      {type === 'pro_plan' && (
        <div className="ResultCampaignPay">
          <div className="PayContainer">
            <div className="ResultCampaignPayContainer">
              <div className="ResultTitleContainer">
                <div className="Title">
                  <div>Awesome 🚀</div>
                  <div>Thank You!</div>
                </div>
                <div className="Description">
                  You’ve now unlocked the Pro features. Grow your audience by uploading multiple apps and take advantage of the more advanced dashboard features.
                </div>
              </div>
              <div className="Image">
                <img className="PayImg" src={welcomeSystem} alt="welcomeSystem" />
              </div>
            </div>

            <ButtonDefault
              className="panelCampaign"
              color="default"
              background="transparent"
              onClick={redirectToApps}
            >
              <div className="buttonContainer">
                <div className="buttonName">Go to my apps</div>
              </div>
            </ButtonDefault>

            <ButtonDefault
              className="newCampaign"
              color="red"
              background="transparent"
              type="button"
              onClick={redirectToCreateCampaign}
            >
              <div className="buttonContainer">
                <div className="buttonName">Create a new campaign</div>
              </div>
            </ButtonDefault>
          </div>
        </div>
      )}
    </PaymentStyled>
  );
}
