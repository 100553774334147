import { useDispatch } from 'react-redux';
import { logOut } from 'redux/actions';
import {
  CondOperator,
  QueryJoin,
  QuerySortOperator,
  RequestQueryBuilder,
  SCondition,
  SConditionKey,
} from '@nestjsx/crud-request';

import { PlainObject } from 'types';

interface RequestOptions {
  auth?: boolean;
  formData?: boolean;
  body?: any;
  headers?: Record<string, string>;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
}
export interface SelectOption<T = number> {
  label: string;
  value: T;
}
export type PagedRequestSortOrder = 'asc' | 'desc';
export type PagedRequestFilterCombinationOperator = 'and' | 'or';
type PagedRequestFilterOperatorWithValue = 'equals' | 'like' | 'in';
type PagedRequestFilterOperatorWithoutValue = 'not null';

export type PagedRequestFilterCombination<T> = {
  operator: PagedRequestFilterCombinationOperator;
  filters: PagedRequestSearch<T>[];
};
export type PagedRequestSortOption<T> = {
  field: string; // TODO: try to revert to keyof T (what about sort=relation.name?)
  order: PagedRequestSortOrder;
};
export type PagedRequestJoinOption<T> = {
  relation: string; // TODO: try to revert to keyof T (what about join=relation.otherRelaion?)
  fields?: string[]; // TODO: try to revert to keyof T[keyof T]
};

type PagedRequestSimpleFilterWithValue<T> = {
  field: string; // TODO: try to revert to keyof T (what about join=relation.id?)
  operator: PagedRequestFilterOperatorWithValue;
  value: any;
};
type PagedRequestSimpleFilterWithoutValue<T> = {
  field: string; // TODO: try to revert to keyof T (what about join=relation.id?)
  operator: PagedRequestFilterOperatorWithoutValue;
};
export type PagedRequestSearch<T> = PagedRequestFilterCombination<T> | PagedRequestSimpleFilter<T>;
export type PagedRequestSimpleFilter<T> =
  | PagedRequestSimpleFilterWithValue<T>
  | PagedRequestSimpleFilterWithoutValue<T>;

export type PagedRequestDTO<T> = {
  fields?: string[]; // TODO: try to revert to keyof T (what about join=relation.id?)
  page?: number;
  limit?: number;
  sortOptions?: PagedRequestSortOption<T>[];
  filter?: PagedRequestSimpleFilter<T>[];
  search?: PagedRequestSearch<T>;
  join?: PagedRequestJoinOption<T>[];
};

interface RequestError extends Error {
  status: number;
  response: any;
}

export function parseError(error: string): string {
  return error || 'Something went wrong';
}

/**
 * Fetch data
 *
 * @param {string} url
 * @param {Object} options
 * @param {string} [options.method] - Request method ( GET, POST, PUT, ... ).
 * @param {string} [options.payload] - Request body.
 * @param {Object} [options.headers]
 *
 * @returns {Promise}
 */
export function request(url: string, options: RequestOptions = {}): Promise<any> {
  const { body, headers, method }: RequestOptions = {
    method: 'GET',
    auth: false,
    formData: false,
    ...options,
  };

  const errors: string[] = [];

  if (!url) {
    errors.push('url');
  }

  if (!body && !['GET', 'DELETE'].includes(method)) {
    errors.push('payload');
  }

  if (errors.length) {
    throw new Error(`Error! You must pass \`${errors.join('`, `')}\``);
  }

  const { auth } = window.store.getState();

  const params: RequestOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    method,
  };

  if (auth.accessToken && options.auth) {
    params.headers.Authorization = `Bearer ${auth.accessToken}`;
  }

  if (options.formData && method !== 'GET') {
    delete params.headers['Content-Type'];
    delete params.headers.Accept;
    params.body = body;
  } else {
    params.body = JSON.stringify(body);
  }
  const host = process.env.REACT_APP_API_URL || 'http://localhost:4200/api';
  return fetch(host + url, params).then(async response => {
    const text = await response.text();
    let content: string | PlainObject;

    try {
      content = JSON.parse(text);
    } catch {
      content = text;
    }

    if (response.status === 401 && auth.isAuthenticated) {
      throw response.status;
    }

    if (response.status > 299) {
      const error = new Error(response.statusText) as RequestError;
      error.status = response.status;
      error.response = content;
      throw error.response;
    } else {
      return content;
    }
  });
}

const transformSortOrder = (sortOrder: PagedRequestSortOrder) => {
  if (sortOrder === 'asc') return 'ASC' as QuerySortOperator;
  if (sortOrder === 'desc') return 'DESC' as QuerySortOperator;

  throw new Error(`Invalid sort order: ${sortOrder}`);
};

const transformSimpleFilterOperator = (filterOperator: PagedRequestFilterOperator) => {
  if (filterOperator === 'equals') return CondOperator.EQUALS;
  if (filterOperator === 'like') return CondOperator.CONTAINS;
  if (filterOperator === 'in') return CondOperator.IN;
  if (filterOperator === 'not null') return CondOperator.NOT_NULL;

  throw new Error(`Invalid filter operator: ${filterOperator}`);
};

const transformCombinationFilterOperator = (
  filterOperator: PagedRequestFilterCombinationOperator,
): SConditionKey => {
  if (filterOperator === 'and') return '$and';
  if (filterOperator === 'or') return '$or';

  throw new Error(`Invalid combination filter operator: ${filterOperator}`);
};
export type PagedRequestFilterOperator =
  | PagedRequestFilterOperatorWithValue
  | PagedRequestFilterOperatorWithoutValue;

const isFilterOperatorWithValue = (
  filterOperator: PagedRequestFilterOperator,
): filterOperator is PagedRequestFilterOperatorWithValue => filterOperator !== 'not null';
const filterHasValue = <T>(
  filter: PagedRequestSimpleFilter<T>,
): filter is PagedRequestSimpleFilterWithValue<T> => isFilterOperatorWithValue(filter.operator);
const isCombinationOperator = (
  filterOperator: PagedRequestFilterOperator | PagedRequestFilterCombinationOperator,
): filterOperator is PagedRequestFilterCombinationOperator =>
  filterOperator === 'and' || filterOperator === 'or';
const isCombinationFilter = <T>(
  search: PagedRequestSearch<T>,
): search is PagedRequestFilterCombination<T> => isCombinationOperator(search.operator);

export function createRequestQuery<T>(pagedRequestDTO: PagedRequestDTO<T>): string {
  let requestQuery = RequestQueryBuilder.create();

  if (pagedRequestDTO.page) {
    requestQuery = requestQuery.setPage(pagedRequestDTO.page);
  }

  if (pagedRequestDTO.limit) {
    requestQuery = requestQuery.setLimit(pagedRequestDTO.limit);
  }

  if (pagedRequestDTO.fields) {
    requestQuery = requestQuery.select(pagedRequestDTO.fields.map(f => f.toString()));
  }

  if (pagedRequestDTO.filter) {
    requestQuery = requestQuery.setFilter(
      pagedRequestDTO.filter.map(f => ({
        field: f.field.toString(),
        operator: transformSimpleFilterOperator(f.operator),
        value: filterHasValue(f) ? f.value : true,
      })),
    );
  }

  if (pagedRequestDTO.search) {
    const buildRequestSearch = (search: PagedRequestSearch<T>): SCondition => {
      if (isCombinationFilter(search)) {
        const requestSearchField = transformCombinationFilterOperator(search.operator);
        const requestSearchFilters = search.filters.map(f => buildRequestSearch(f));
        return {
          [requestSearchField]: requestSearchFilters,
        };
      }
      const requestSearchOperator = transformSimpleFilterOperator(search.operator);
      const requestSearchField = search.field;
      const requestSearchValue = filterHasValue(search) ? search.value : true;
      return {
        [requestSearchField]: { [requestSearchOperator]: requestSearchValue },
      };
    };

    requestQuery = requestQuery.search(buildRequestSearch(pagedRequestDTO.search));
  }

  if (pagedRequestDTO.sortOptions) {
    const sortOptions = pagedRequestDTO.sortOptions.map(s => ({
      field: s.field.toString(),
      order: transformSortOrder(s.order),
    }));

    requestQuery = requestQuery.sortBy(sortOptions);
  }

  if (pagedRequestDTO.join) {
    pagedRequestDTO.join.forEach(j => {
      let queryJoin: QueryJoin = { field: j.relation.toString() };
      if (j.fields) {
        queryJoin = { ...queryJoin, select: j.fields.map(f => f.toString()) };
      }

      requestQuery = requestQuery.setJoin(queryJoin);
    });
  }

  return requestQuery.query(); // TODO: use .env to pass false to query method (encoded: false)
}
