/**
 * @module Sagas/GitHub
 * @desc GitHub
 */

import { all, call, put, takeLatest } from 'redux-saga/effects';

import { parseError, request } from 'modules/client';

import { ActionTypes } from 'literals';

import { CampaignData } from 'types';

export function* getCampaign() {
  try {
    const response: CampaignData = yield call(request, '/campaign/list', {
      auth: true,
    });

    yield put({
      type: ActionTypes.GET_CAMPAIGN_SUCCESS,
      payload: response,
    });
  } catch (err) {
    if (err === 401) {
      yield put({
        type: ActionTypes.SHOW_ALERT,
        payload: {
          message: 'Session expired, please sign in again.',
          position: 'bottom-right',
          timeout: 5,
          id: Math.random().toString(36).substr(2, 5),
          variant: 'warning',
        },
      });
      yield put({
        type: ActionTypes.USER_LOGOUT_REQUEST,
      });
    }
    yield put({
      type: ActionTypes.GET_CAMPAIGN_FAILURE,
      payload: err,
      meta: { err },
    });

    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: parseError(err.message),
        position: 'bottom-right',
        timeout: 5,
        id: Math.random().toString(36).substr(2, 5),
        variant: 'warning',
      },
    });
  }
}

export function* getCampaignId({ payload }: any) {
  try {
    const response: CampaignData = yield call(request, `/campaign/${payload.id}`, {
      auth: true,
    });

    yield put({
      type: ActionTypes.GET_CAMPAIGN_ID_SUCCESS,
      payload: response,
    });
  } catch (err) {
    if (err === 401) {
      yield put({
        type: ActionTypes.SHOW_ALERT,
        payload: {
          message: 'Session expired, please sign in again.',
          position: 'bottom-right',
          timeout: 5,
          id: Math.random().toString(36).substr(2, 5),
          variant: 'warning',
        },
      });
      yield put({
        type: ActionTypes.USER_LOGOUT_REQUEST,
      });
    }
    yield put({
      type: ActionTypes.GET_CAMPAIGN_ID_FAILURE,
      payload: err,
      meta: { err },
    });

    yield put({
      type: ActionTypes.SHOW_ALERT,
      payload: {
        message: parseError(err.message),
        position: 'bottom-right',
        timeout: 5,
        id: Math.random().toString(36).substr(2, 5),
        variant: 'warning',
      },
    });
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.GET_CAMPAIGN_REQUEST, getCampaign)]);
  yield all([takeLatest(ActionTypes.GET_CAMPAIGN_ID_REQUEST, getCampaignId)]);
}
