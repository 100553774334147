import styled from 'styled-components';

export const StyledBillingTable = styled.div`
  font-family: DM Sans;
  font-weight: 500;
  font-size: 14px;

  .table-head {
    border-radius: 12px;
    .table-row {
      color: white;
      background-color: #6a52b0;
      text-transform: uppercase;
    }
  }

  p,
  td {
    font-size: 14px;
  }
  .status {
    color: #38bfa1;
    border: 1px solid #38bfa1;
    border-radius: 10px;
    text-align: center;
    padding: 5px;
  }
`;
