import styled from 'styled-components';

export const CampaingContainer = styled.div`
  margin: 40px 0 0 40px;
  display: flex;
  .MuiButton-root {
    text-transform: none;
    font-family: DM Sans;
  }
  .nextStep {
    background: #6a52b0;
    border-radius: 12px;
    width: 123px;
    height: 40px;
    margin: 5px 0 0 50px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;

    &:hover {
      background: #553aa3;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
  }
  .stepContent {
    margin-bottom: 40px;
  }
  .campaignPayment {
    margin-left: 50px;
  }
  .stepsInfoContainer {
    margin-left: 50px;
    &.campLook {
      display: flex;
      flex-wrap: wrap;
      .campaingLook {
        margin-right: 40px;
      }
    }
    .campaignType {
      display: flex;
      label {
        margin-bottom: 18px;
        &:before {
          margin-right: 15px;
        }
      }
      .svgToolTip {
        margin-left: 5px;
        margin-top: 2px;
      }
    }
    .title {
      font-family: DM Sans;
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      text-align: left;
      margin: 0 0 20px 0;
    }
    .dateSelect {
      display: flex;
      align-items: baseline;
      //justify-content: space-between;
      .dateSelectForm {
        //width: 200px;
      }
      .dateSelectText {
        margin-right: 10px;
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.22580644488334656px;
        text-align: left;
        color: #767b7f;
      }
    }
    .timeline {
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.29032257199287415px;
      text-align: left;
      margin-bottom: 10px;
    }
    .campaignCost {
      display: flex;
      justify-content: space-between;
      font-family: DM Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: -0.3709677457809448px;
      text-align: left;
    }
    .willShow {
      margin-top: 20px;
      margin-bottom: 10px;
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      color: #4d5157;
    }

    .willShowIn {
      div {
        margin-bottom: 10px;
      }
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.29032257199287415px;
      text-align: left;
      color: #6a52b0;
      .total {
        font-style: normal;
        font-weight: 700;
        margin-top: 30px;
        border-top: 1px solid #d8d8d8;
        display: flex;
        justify-content: space-between;
        padding: 7px 0 0 0;
      }
    }
    .appIconContainer {
      img {
        margin-right: 5px;
      }
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      color: #1c1939;
    }
    .campaingLook {
      .chooseColorText {
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: #4d5157;
        margin: 31px 0 15px 0;
      }
      .changeBannerBack {
        display: flex;
        align-items: center;
        margin-top: 12px;
        .toggleText {
          margin-left: 15px;
          font-family: DM Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          color: #4d5157;
        }
      }
    }
  }
  .chooseColorDisabled {
    .colorPickerLabel,
    .chooseColorText {
      color: #d3d3d3 !important;
    }
    label {
      cursor: not-allowed;
    }
  }
  .stepper {
    background: #f5f5f9;
    width: 260px;
    height: 250px;
    border-radius: 7px;

    .stepsTitle {
      margin-top: 30px;
      margin-left: 22px;
      width: 250px;
      font-family: DM Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: -0.3709677457809448px;
      text-align: left;
    }
  }
  .reviewWrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .Testing {
    padding: 0 30px 40px 22px;
    background: #f5f5f9;

    .stepsTitle {
      font-family: DM Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 29px;
      letter-spacing: -0.370968px;

      color: #1c1939;
    }

    .MuiStepConnector-root {
      display: none;
    }

    .MuiStepConnector-line {
      display: none;
    }

    .MuiStep-alternativeLabel {
      flex: 0;
      margin-top: 25px;
    }

    .MuiStepLabel-root {
      flex-direction: row;

      svg {
        width: 10px;
        height: 10px;
      }
    }

    .MuiStepLabel-labelContainer {
      margin-left: 15px;
    }

    .MuiStepLabel-label {
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      color: #767b7f;

      &.MuiStepLabel-active {
        color: #6a52b0;
      }
      &.MuiStepLabel-completed {
        color: #38bfa1;
      }
    }

    .MuiStepLabel-alternativeLabel {
      text-align: right;
      margin: 0;
    }
  }
`;

export const campaingLook = styled.div``;
