import React from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .logoSvg {
    height: 35px;
    width: 35px;
  }

  .appName {
    margin: 4px 0 0 15px;
    font-family: DM Sans;
    color: #8769e0;
    font-size: 24px;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.37800002098083496px;
    text-align: center;
  }
`;

function Logo({ type }) {
  return type === 'white' ? (
    <Link to="/" className="logoWrapper">
      <Wrapper>
        <SVG className="logoSvg" src={`${process.env.PUBLIC_URL}/media/brand/whiteLogo.svg`} />
        <p className="appName">WeShareApps</p>
      </Wrapper>
    </Link>
  ) : (
    <Link to="/" className="logoWrapper">
      <Wrapper>
        <SVG
          className="logoSvg"
          src={`${process.env.PUBLIC_URL}/media/brand/weShareAppsLogo.svg`}
        />
        <p className="appName">WeShareApps</p>
      </Wrapper>
    </Link>
  );
}

export default Logo;
