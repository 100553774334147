import React, { useEffect, useRef } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { createCostumer, createSubscription, showDialog } from 'redux/actions';
import { AvForm } from 'availity-reactstrap-validation';
import { MainContainer } from 'pages/SignIn/Style';
import { ButtonDefault, Card, CheckBoxDefault, CheckBoxStyled } from 'styles';

import { useShallowEqualSelector } from 'modules/hooks';

import { STATUS } from 'literals';

import { Wrapper } from 'components/Logo';
import Payment from 'components/Payment/payment';

import { StoreState } from 'types';

function ProPlan() {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    type: 'pro_plan',
    amount: 499,
    priceId: process.env.REACT_APP_PRO_PLAN_PRICE,
    conditions: false,
    email: '',
    name: '',
  });

  const handleClickOpenDialog = (type: string) => {
    dispatch(
      showDialog({
        type,
      }),
    );
  };

  const { costumerId, status } = useShallowEqualSelector((s: StoreState) => s.payment);
  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleChecked = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (costumerId) {
      dispatch(createSubscription(costumerId, state.priceId));
    }
  }, [costumerId]);

  const onSubmit = () => {
    dispatch(createCostumer(state.email));
  };
  const avForm = useRef<{ reset: any }>();

  return (
    <MainContainer className="becomePro">
      <div className="leftSide">
        <Link to="/" className="logoWrapper">
          <Wrapper>
            <SVG
              className="logoSvg"
              src={`${process.env.PUBLIC_URL}/media/brand/proPlanLogo.svg`}
            />
            <p className="appName">WeShareApps</p>
          </Wrapper>
        </Link>
        <div className="welcomeText">
          <h1>Get Unlimited</h1>
          <p className="welcomeP">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum nam vestibulum morbi ac,
            faucibus mattis metus, enim.
          </p>
          <Card>
            <div className="pricingItemTitle withBg">
              <div className="title">PRO</div>
              <div className="pricingPlan">${state.amount.toFixed(2)}/ Anual</div>
            </div>
            <div className="pricingItemContent">
              <div className="pricingItemContainer">
                <div className="pricingColumn">Apps</div>
                <div className="pricingColumn unlimited">Unlimited</div>
              </div>
              <div className="pricingItemContainer">
                <div className="pricingColumn">Statistics (app usage)</div>
                <div className="pricingColumn">
                  <CheckBoxStyled>
                    <div className="checkbox">
                      <input
                        className="custom-checkbox"
                        name="app"
                        readOnly={true}
                        type="checkbox"
                        checked={true}
                      />
                      <label htmlFor="app" />
                    </div>
                  </CheckBoxStyled>
                </div>
              </div>
              <div className="pricingItemContainer">
                <div className="pricingColumn">App promotions</div>
                <div className="pricingColumn">
                  <CheckBoxStyled>
                    <div className="checkbox">
                      <input
                        className="custom-checkbox"
                        name="promotions"
                        readOnly={true}
                        type="checkbox"
                        checked={true}
                      />
                      <label htmlFor="promotions" />
                    </div>
                  </CheckBoxStyled>
                </div>
              </div>
              <div className="pricingItemContainer">
                <div className="pricingColumn">Weekly results</div>
                <div className="pricingColumn">
                  <CheckBoxStyled>
                    <div className="checkbox">
                      <input
                        className="custom-checkbox"
                        type="checkbox"
                        readOnly={true}
                        checked={true}
                        name="weekly"
                      />
                      <label htmlFor="weekly" />
                    </div>
                  </CheckBoxStyled>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className="rightSide">
        <div className="rightSideContainer">
          <h1 className="signTitle">Become Pro 😎</h1>
          <div className="planSummaryContainer">
            <div className="planPayTitle">Plan Summary</div>
            <div className="pricingContainer">
              <div className="pricingRow">
                <div className="pricingName">WeShareApp Pro</div>
                <div className="pricingAmount">${state.amount.toFixed(2)} USD</div>
              </div>
              <div className="pricingRow sales">
                <div className="pricingName">Sales Tax</div>
                <div className="pricingAmount">$0.00 USD</div>
              </div>
            </div>
            <div className="totalAnnual">
              <div>Total (Annual)</div>
              <div>${state.amount.toFixed(2)} USD</div>
            </div>
          </div>
          <AvForm ref={avForm} onValidSubmit={onSubmit} model={state}>
            <Payment handleChange={handleChange} state={state} />
            <CheckBoxDefault className="termsCheck">
              <label className="checkbox-button">
                <input
                  type="checkbox"
                  className="checkbox-button__input"
                  id="conditions"
                  name="conditions"
                  defaultChecked={state.conditions}
                  onChange={handleChecked}
                />
                <span className="checkbox-button__control" />
                <span className="checkbox-button__label">
                  I’ve read the{' '}
                  <a onClick={e => handleClickOpenDialog('terms')} className="link">
                    terms and conditions.
                  </a>
                </span>
              </label>
            </CheckBoxDefault>
            <ButtonDefault
              color="default"
              type="submit"
              disabled={!state.conditions || status === STATUS.RUNNING}
              className="submitPro"
            >
              <div className="buttonContainer">
                <div className="buttonName">Pay $ {state.amount}</div>
              </div>
            </ButtonDefault>
          </AvForm>
        </div>
      </div>
    </MainContainer>
  );
}

export default ProPlan;
