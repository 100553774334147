import React from 'react';
import { useDispatch } from 'react-redux';
import { showDialog } from 'redux/actions';

import { FooterContainer } from 'components/Footer/Style';

function Footer() {
  const dispatch = useDispatch();
  const handleClickOpenDialog = (type: string) => {
    dispatch(
      showDialog({
        type,
      }),
    );
  };

  return (
    <FooterContainer>
      <div className="allRights">2021. WeShareApps - All rights reserverd.</div>
      <div>
        <a onClick={e => handleClickOpenDialog('terms')} className="footerLinks termsCond">
          Terms and Conditions
        </a>
        <a onClick={e => handleClickOpenDialog('privacy')} className="footerLinks">
          Privacy Policy
        </a>
      </div>
    </FooterContainer>
  );
}

export default Footer;
