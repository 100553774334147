import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@material-ui/core';
import review from 'images/review.png';
import signInImage from 'images/signInLogo.png';

import Logo from 'components/Logo';

export function CarouselUI() {
  const items = [
    {
      name: 'WeShareApps Console',
      description:
        'The WeShareApps Publisher Console helps you as a WPA developer to publish and track your apps. We help you get insights through our dashboards and make it easier to distibute your app worldwide.',
      img: review,
    },
    {
      name: 'WeShareApps Console',
      description: 'Get the best apps and save your device storage',
      img: signInImage,
    },
  ];

  return (
    <Carousel className="carousel">
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item({ item }) {
  return (
    <React.Fragment>
      <Logo type="white" />
      <Paper className="signInWrap">
        <img width="360px" height="322px" className="welcomeImg" src={item.img} alt="review" />
        <div className="welcomeText">
          <h1>{item.name}</h1>
          <p className="welcomeP">{item.description}</p>
        </div>
      </Paper>
    </React.Fragment>
  );
}
