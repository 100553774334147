import styled from 'styled-components';
import { Container, responsive } from 'styled-minimal';

import { headerHeight, spacer } from 'modules/theme';

export const HeaderWrapper = styled.header`
  .MuiMenuItem-root {
    font-size: 14px !important;
  }
  background-color: #f5f5f9;
  height: ${headerHeight}px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;

  #menu-list-grow {
    font-family: Roboto;
    padding: 6px 0;
    max-width: 124px;
    text-align: center;
    li {
      padding: 10px 0 10px 16px;
    }
  }
`;
export const Logout = styled.button`
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 1.3rem;
  padding: ${spacer(2)};

  ${responsive({ lg: { fontSize: '1.6rem' } })}; /* stylelint-disable-line */

  &.active {
    color: #fff;
  }

  span {
    display: inline-block;
    margin-right: 0.4rem;
    text-transform: uppercase;
  }
`;
export const HeaderContainer = styled(Container)`
  display: flex;
  height: 100%;
  padding: 0 40px;
  justify-content: space-between;
  align-items: center;
  .navigationContainer {
    display: flex;
    .navigationLinkContainer {
      cursor: pointer;
      margin-top: 10px;
      margin-left: 50px;
      .active {
        color: #8769e1 !important;
        border-bottom: 1px solid #8769e1;
      }
      .navigationLink {
        padding-bottom: 5px;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
        color: #606263;
        &:hover {
          color: #8769e1;

          border-bottom: 1px solid #8769e1;
        }
      }
    }
  }
  .headerButtons {
    display: flex;
    .headerSvgContainer {
      margin-left: 20px;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      //background: rgba(106, 82, 176, 0.19);
      &:hover {
        background: rgba(106, 82, 176, 0.19);
      }
      .headerSvg {
        margin: 10px;
      }
    }
  }
`;
