import { createReducer } from 'modules/helpers';

import { ActionTypes, STATUS } from 'literals';

import { SupportState } from 'types';

export const supportState: SupportState = {
  record: null,
  status: STATUS.IDLE,
  message: '',
};

export default {
  support: createReducer<SupportState>(
    {
      [ActionTypes.SUPPORT_POST_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.SUPPORT_POST_SUCCESS]: draft => {
        draft.status = STATUS.SUCCESS;
      },
      [ActionTypes.SUPPORT_POST_FAILURE]: draft => {
        draft.status = STATUS.ERROR;
      },
    },
    supportState,
  ),
};
