import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { getUser, hideDialog, isProPlan, showAlert } from 'redux/actions';
import CreateCampaign from 'pages/Campaign/create/View';
import MyCampaignList from 'pages/Campaign/List';
import CampaignView from 'pages/Campaign/View';
import LoginSuccessPage from 'pages/LoginSuccessPage';
import MyAppPage from 'pages/MyApps/Page';
import NotFound from 'pages/NotFound';
import PasswordRecovery from 'pages/PasswordRecovery';
import ProPlan from 'pages/ProPlan';
import ServerError from 'pages/ServerError';
import SignIn from 'pages/SignIn/View';
import SignUp from 'pages/SignUp/View';
import Support from 'pages/Support/View';
import UpgradeRole from 'pages/UpgradeRole';
import WSA from 'pages/WSA/View';
import { Main } from 'RootStyle';
import styled, { ThemeProvider } from 'styled-components';

import history from 'modules/history';
import { useShallowEqualSelector } from 'modules/hooks';
import theme from 'modules/theme';

import config from 'config';

import RoutePrivate from 'containers/RoutePrivate';
import RoutePublic from 'containers/RoutePublic';
import SystemAlerts from 'containers/SystemAlerts';

import AppDialog from 'components/AppDialog';
import BillingDialog from 'components/Billing/BillingDialog';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { PaymentSuccess } from 'components/Payment/PaymentSuccess';
import PrivacyPolicy from 'components/PrivacyPolicy';
import SupportDialog from 'components/SupportDialog';
import TermsCondDialog from 'components/TermsCondDialog';
import UserDialog from 'components/UserDialog';

import { StoreState } from 'types';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
`;

function Root() {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useShallowEqualSelector((s: StoreState) => s.auth);
  const previousIsAuthenticated = usePrevious(isAuthenticated);
  const { dialog, isProPlanPage } = useShallowEqualSelector((s: StoreState) => s.app);
  const handleCloseDialog = () => {
    dispatch(hideDialog());
  };
  useEffect(() => {
    if (previousIsAuthenticated !== isAuthenticated && isAuthenticated) {
      dispatch(getUser());
      dispatch(
        showAlert(`Hello, ${user ? user.username : ''}! And welcome!`, {
          variant: 'success',
          timeout: 3,
        }),
      );
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const { location } = history;
    if (location.pathname.match(/pro_plan/)) {
      dispatch(
        isProPlan({
          isProPlanPage: false,
        }),
      );
    } else {
      dispatch(
        isProPlan({
          isProPlanPage: true,
        }),
      );
    }
  }, [history.location.pathname]);

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <AppWrapper data-testid="app">
          <Helmet
            defer={false}
            htmlAttributes={{ lang: 'pt-br' }}
            encodeSpecialCharacters={true}
            defaultTitle={config.name}
            titleTemplate={`%s | ${config.name}`}
            titleAttributes={{ itemprop: 'name', lang: 'pt-br' }}
          >
            <link
              href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          {isAuthenticated && isProPlanPage && <Header />}
          <Main isAuthenticated={isAuthenticated && isProPlanPage}>
            <AppDialog open={dialog.type === 'app'} onClose={handleCloseDialog} />
            <UserDialog open={dialog.type === 'user'} onClose={handleCloseDialog} />
            <BillingDialog open={dialog.type === 'billing'} onClose={handleCloseDialog} />
            <SupportDialog open={dialog.type === 'support'} onClose={handleCloseDialog} />
            <TermsCondDialog open={dialog.type === 'terms'} onClose={handleCloseDialog} />
            <PrivacyPolicy open={dialog.type === 'privacy'} onClose={handleCloseDialog} />
            <Switch>
              <RoutePublic
                isAuthenticated={isAuthenticated}
                path="/login/success"
                to="/"
                component={LoginSuccessPage}
              />
              <RoutePublic
                isAuthenticated={isAuthenticated}
                path="/sign-up"
                to="/login"
                component={SignUp}
              />
              <RoutePublic
                isAuthenticated={isAuthenticated}
                path="/login"
                to="/"
                component={SignIn}
              />
              <RoutePublic
                isAuthenticated={isAuthenticated}
                path="/upgrade-role"
                component={UpgradeRole}
              />
              <RoutePrivate
                isAuthenticated={isAuthenticated}
                path="/campaign/:id"
                component={CampaignView}
              />
              <RoutePrivate
                isAuthenticated={isAuthenticated}
                path="/campaign-create"
                component={CreateCampaign}
              />
              <RoutePrivate
                isAuthenticated={isAuthenticated}
                path="/campaign-edit"
                component={CreateCampaign}
              />
              <RoutePrivate
                isAuthenticated={isAuthenticated}
                path="/payment"
                component={PaymentSuccess}
              />
              <RoutePrivate isAuthenticated={isAuthenticated} path="/support" component={Support} />
              <RoutePrivate isAuthenticated={isAuthenticated} path="/wsa" component={WSA} />
              <RoutePrivate
                isAuthenticated={isAuthenticated}
                path="/campaign"
                component={MyCampaignList}
              />
              <RoutePrivate
                exact
                isAuthenticated={isAuthenticated}
                path="/"
                component={MyAppPage}
              />
              <RoutePrivate
                exact
                isAuthenticated={isAuthenticated}
                path="/pro_plan"
                component={ProPlan}
              />
              <RoutePublic
                exact
                isAuthenticated={isAuthenticated}
                path="/password-recovery"
                to="/login"
                component={PasswordRecovery}
              />

              <Route path="/server-error" component={ServerError} />
              <Route component={NotFound} />
            </Switch>
            {isAuthenticated && isProPlanPage && <Footer />}
          </Main>
          <SystemAlerts />
        </AppWrapper>
      </ThemeProvider>
    </Router>
  );
}

export default Root;
