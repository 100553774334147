import app, { appState } from './app';
import auth, { authState } from './auth';
import billing, { billingState } from './billing';
import campaign, { campaignState } from './campaign';
import payment, { paymentState } from './payment';
import user, { userState } from './user';
import support, { supportState } from './support';

export const initialState = {
  app: appState,
  billing: billingState,
  payment: paymentState,
  auth: authState,
  user: userState,
  support: supportState,
  campaign: campaignState,
};

export default {
  ...app,
  ...billing,
  ...campaign,
  ...support,
  ...user,
  ...auth,
  ...payment,
};
